import React from "react";
import { FormGroup, Label, Button } from "reactstrap";
import { Form, Input  } from 'antd';
import { CButtonGroup, CFormCheck, CButton } from '@coreui/react';
import { useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import Map from '../campaign/Map';
import Slider, { createSliderWithTooltip } from "rc-slider";

const FormItem = Form.Item;



const Step3 = props => {
  if (props.currentStep !== 3) {
    return null;
  }

  useEffect(() => {

  }, []);

  return (
    <>
      <FormGroup>
      <div className="row align-items-stretch">
            <div className="col-md-2">
                <Label>Target Country</Label>
            </div>
                            
            <div className="col-md-10">
            <FormItem 
                validateStatus={props.campaign.targetCountry.validateStatus}
                help={props.campaign.targetCountry.errorMsg}>
                <Input
                    type="text"
                    name="targetCountry"
                    id="targetCountry"
                    placeholder="Enter the Target Country"
                    value={props.campaign.targetCountry.value} // Prop: The email input data
                    //onChange={(event) => props.handleInputChange(event, props.validateTargetCountry)} // Prop: Puts data into the state
                    readonly
                />
            </FormItem>
            </div>


            <div className="row align-items-stretch">
              <div className="col-md-2">
                  <Label>Gender</Label>
              </div>         
              <div className="col-md-10">
              <FormItem>
                  <CButtonGroup role="group" aria-label="Basic checkbox toggle button group"
                  onChange={(event) => props.handleOptionChange(event)}
                  >
                  <CFormCheck
                    type="radio"
                    button={{ color: 'info', variant: 'outline'}}
                    checked={'both' === props.campaign.targetGender.value}
                    name="targetGender"
                    id="both"
                    autoComplete="off"
                    label="Both"
                  />
                  <CFormCheck
                    type="radio"
                    button={{ color: 'info', variant: 'outline' }}
                    checked={'female' === props.campaign.targetGender.value}
                    name="targetGender"
                    id="female"
                    autoComplete="off"
                    label="Female"
                  />
                  <CFormCheck
                    type="radio"
                    button={{ color: 'info', variant: 'outline' }}
                    checked={'male' === props.campaign.targetGender.value}
                    name="targetGender"
                    id="male"
                    autoComplete="off"
                    label="Male"
                  />
                </CButtonGroup>
              </FormItem>
              </div>
            </div>

            <div className="col-md-2">
                <Label>Target Area</Label>
            </div>
                            
            <div className="col-md-10">
              <Map
                campaign={props.campaign}
                handleCoordinateChange={props.handleCoordinateChange}
              />
              <br/>
              <Slider
              name="targetRadius"
              min={1}
              max={1000}
              value={props.campaign.targetRadius.value}
              onChange={(event) => props.handleTargetRadiusChange(event)} 
              railStyle={{height: 5}}
              handleStyle={{
                height: 28,
                width: 28,
                marginLeft: 0,
                marginTop: -14,
                backgroundColor: "#0074d9",
                border: 10
              }}
              trackStyle={{
                background: "none"
              }}
              />
              <div style={{ margin: 5 }}>
                <p>{props.campaign.targetRadius.value} km</p>
              </div>
            </div>

            <div className="col-md-2">
                <Label>Number of Leads</Label>
            </div>
                            
            <div className="col-md-10">
            <FormItem 
                validateStatus={props.campaign.numberOfLeads.validateStatus}
                help={props.campaign.numberOfLeads.errorMsg}>
                <Input
                    type="number"
                    min="1"
                    pattern="^-?[0-9]\d*\.?\d*$"
                    name="numberOfLeads"
                    id="numberOfLeads"
                    placeholder="Enter Number of Leads"
                    value={props.campaign.numberOfLeads.value} // Prop: The email input data
                    onChange={(event) => props.handleInputChange(event, props.validateNumberOfLeads)} // Prop: Puts data into the state
                />
            </FormItem>
            </div>
        </div>

       

    </FormGroup>
    </>
  );
};

export default Step3;
