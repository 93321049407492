exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,700,400italic,700italic);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700);", ""]);
exports.push([module.id, "@import url(https://www.cssscript.com/wp-includes/css/sticky.css);", ""]);

// module
exports.push([module.id, "\r\n", ""]);

// exports
