import React, { Component, useRef } from "react";
import { getCampaignDetail, postCampaignDetail } from '../util/APIUtils';
import { notification } from 'antd';
import { SERVER_URL } from '../constants';

import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter
} from "reactstrap";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

import styled from "styled-components";
import MultiStepProgressBar from "./MultiStepProgressBar";

class MasterForm extends Component {
   


  constructor(props) {
    super(props);
    this.iFrameRef = React.createRef();

    /*
        
    https://codesandbox.io/s/j7y1879179?file=/src/index.js:256-307
    https://coreui.io/product/react-dashboard-template/?theme=light-v3#live-preview
    https://coreui.io/react/docs/components/accordion/

  private Long campaignId;
	private Boolean conversionrequestJsonFocus;

	*/
    this.state = {
        currentStep: 1,
        submit: {
            value: 'unsubmitted'
        },
        campaignId: {
          value: null
        },
        campaignName: {
            value: ''
        },
        emailSubject: {
            value: ''
        },
        senderName: {
            value: ''
        },
        senderEmail: {
            value: 'noreply@bilrost.io'
        },
        eventType: {
            value: 'onlineEvent'
        },
        campaignType: {
            value: 'purchase'
        },
        channel: {
          value: 'email'
        },
        conversionFocus: {
          value: false
        },
        promotionPeriod: {
          value: 2
        },
        commissionPercentage: {
          value: 30
        },
        chargeOfClickThrough: {
          value: 5
        },
        targetCountry: {
          value: 'United Kingdom'
        },
        targetGender: {
          value: 'both'
        },
        targetArea: {
          value: 'United Kingdom'
        },
        targetRadius: {
          value: 1
        },
        targetLat: {
          value: null
        },
        targetLng: {
          value: null
        },
        numberOfLeads: {
          value: 1
        },
        emailStatus: {
          value: 0
        },
        emailTemplateJson: {
          value: ''
        },
        emailTemplateHtml: {
          value: ''
        }
        
    }

    // Bind the submission to handleChange()
    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.loadCampaign = this.loadCampaign.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleConversionFocusChange = this.handleConversionFocusChange.bind(this);
    this.handlePromotionPeriodChange = this.handlePromotionPeriodChange.bind(this);
    this.handleCommissionPercentageChange = this.handleCommissionPercentageChange.bind(this);
    this.handleChargeOfClickThrough = this.handleChargeOfClickThrough.bind(this);
    this.validateCampaignName = this.validateCampaignName.bind(this);
    this.validateEmailSubject = this.validateEmailSubject.bind(this);
    this.validateSenderName = this.validateSenderName.bind(this);
    this.validateSenderEmail = this.validateSenderEmail.bind(this);
    this.validateTargetCountry = this.validateTargetCountry.bind(this);
    this.validateNumberOfLeads = this.validateNumberOfLeads.bind(this);
    this.handleTargetRadiusChange = this.handleTargetRadiusChange.bind(this);
    this.handleCoordinateChange = this.handleCoordinateChange.bind(this);
    this.setJsonTemplate = this.setJsonTemplate.bind(this);
    this.setHtmlTemplate = this.setHtmlTemplate.bind(this);
    
    
    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);

  }

  componentDidMount() {
    this.loadCampaign();
  }

  loadCampaign(){
    if (localStorage.getItem("campaignId")){
      getCampaignDetail(localStorage.getItem("campaignId"))
      .then(response => {
        console.log(response);
        if (response.success){
          this.setState({
            campaignId: {
              value: localStorage.getItem("campaignId")
            },
            campaignName: {
              value: response.campaignName
            },
            emailSubject: {
              value: response.emailSubject
            },
            senderName: {
              value: response.senderName
            },
            senderEmail: {
              value: response.senderEmail
            },
            eventType: {
              value: response.eventType
            },
            campaignType: {
              value: response.campaignType
            },
            channel: {
              value: response.channel
            },
            conversionFocus: {
              value: response.conversionFocus
            },
            promotionPeriod: {
              value: response.promotionPeriod
            },
            commissionPercentage: {
              value: response.commissionPercentage
            },
            chargeOfClickThrough: {
              value: response.chargeOfClickThrough
            },
            targetCountry: {
              value: response.targetCountry
            },
            targetGender: {
              value: response.targetGender
            },
            targetArea: {
              value: response.targetArea
            },
            targetRadius: {
              value: response.targetRadius
            },
            targetLat: {
              value: response.targetLat
            },
            targetLng: {
              value: response.targetLng
            },
            numberOfLeads: {
              value: response.numberOfLeads
            },
            emailStatus: {
              value: response.emailStatus
            },
            emailTemplateJson: {
              value: response.emailTemplateJson
            },
            emailTemplateHtml: {
              value: response.emailTemplateHtml
            }
          });
          
          localStorage.setItem('emailTemplateJson', response.emailTemplateJson);


          if (response.emailStatus == 0){
            this.setState({
              submit: {
                value: 'unsubmitted'
              }
            });
          } else {
            this.setState({
              submit: {
                value: 'submitted'
              }
            });
          }

        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
    }
  }

  setCurrentStep(currentStep) {
    this.setState({
      currentStep: currentStep
    });
  }
  
  handleInputChange(event, validationFun) {
    const target = event.target;
    const inputName = target.name;        
    const inputValue = target.value;

    this.setState({
        [inputName] : {
            value: inputValue,
            ...validationFun(inputValue)
        }
    });
  }

  handleOptionChange(event) {
    console.log(event);
    const target = event.target;
    const inputName = target.name;        
    const inputValue = target.id;

    this.setState({
        [inputName] : {
            value: inputValue,
        }
    });

    console.log(this.state);
  }

  handleConversionFocusChange(event) {
    console.log(event);
    const newValue = !this.state.conversionFocus.value;
    this.setState({
      conversionFocus : {
            value: newValue,
        }
    });

    console.log(this.state);
  }
  
  handlePromotionPeriodChange(promotionPeriod) {
    var promoPec = (promotionPeriod - 2) / 28;
    var comisPec = (this.state.commissionPercentage.value - 15) / 15;
    var clickPec = (this.state.chargeOfClickThrough.value - 1) / 4;

    if ( (1-promoPec) > comisPec){
      var adjustedCom = (15*(1-promoPec) + 15) | 0;
      this.setState({
        commissionPercentage : {
              value: adjustedCom,
          }
      });
    }

    if ( (1-promoPec) > clickPec){
      var adjustedClick = (4*(1-promoPec) + 1) | 0;
      this.setState({
        chargeOfClickThrough : {
              value: adjustedClick,
          }
      });
    } 

    this.setState({
      promotionPeriod : {
          value: promotionPeriod,
      }
    });
  }

  handleCommissionPercentageChange(commissionPercentage) {
    var comisPec = (commissionPercentage - 15) / 15;
    var promoPec = (this.state.promotionPeriod.value - 2) / 28;

    if ( (1-promoPec) > comisPec){
      var adjustedPro = (28*(1-comisPec) + 2) | 0;
      this.setState({
        promotionPeriod : {
              value: adjustedPro,
          }
      });
    } 
    
    this.setState({
      commissionPercentage : {
            value: commissionPercentage,
        }
    });
  }

  handleChargeOfClickThrough(chargeOfClickThrough) {
    var clickPec = (chargeOfClickThrough - 1) / 4;
    var promoPec = (this.state.promotionPeriod.value - 2) / 28;

    if ( (1-promoPec) > clickPec){
      var adjustedPro = (28*(1-clickPec) + 2) | 0;
      this.setState({
        promotionPeriod : {
              value: adjustedPro,
          }
      });
    } 

    this.setState({
      chargeOfClickThrough : {
            value: chargeOfClickThrough,
        }
    });
  }

  handleTargetRadiusChange(event) {
    console.log(event);

    this.setState({
      targetRadius : {
            value: event,
        }
    });

    console.log(this.state);
  }

  handleCoordinateChange(targetLat, targetLng) {
    console.log(targetLat);
    console.log(targetLng);

    this.setState({
      targetLat : {
            value: targetLat,
        }
    });

    this.setState({
      targetLng : {
            value: targetLng,
        }
    });

    console.log(this.state);
  }

  validateCampaignName = (campaignName) => {
    if(!campaignName) {
        return {
            validateStatus: 'error',
            errorMsg: 'Campaign Name may not be empty'                
        }
    } else if (campaignName.length > 50) {
        return {
            validateStatus: 'error',
            errorMsg: `Campaign Name is too long (Maximum 50 characters allowed.)`
        }
    } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
          };            
    }
  }

  validateEmailSubject = (emailSubject) => {
    if(!emailSubject) {
        return {
            validateStatus: 'error',
            errorMsg: 'Email Subject may not be empty'                
        }
    } else if (emailSubject.length > 100) {
        return {
            validateStatus: 'error',
            errorMsg: `Email Subject is too long (Maximum 100 characters allowed.)`
        }
    } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
          };            
    }
  }
  validateSenderName = (senderName) => {
    if(!senderName) {
        return {
            validateStatus: 'error',
            errorMsg: 'Sender Name may not be empty'                
        }
    } else if (senderName.length > 50) {
        return {
            validateStatus: 'error',
            errorMsg: `Sender Name is too long (Maximum 50 characters allowed.)`
        }
    } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
          };            
    }
  }
  validateSenderEmail = (SenderEmail) => {
    if(!SenderEmail) {
        return {
            validateStatus: 'error',
            errorMsg: 'Email may not be empty'                
        }
    }

    const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
    if(!EMAIL_REGEX.test(SenderEmail)) {
        return {
            validateStatus: 'error',
            errorMsg: 'Email not valid'
        }
    }

    if(SenderEmail.length > 50) {
        return {
            validateStatus: 'error',
            errorMsg: `Email is too long (Maximum 50 characters allowed)`
        }
    }

    return {
        validateStatus: null,
        errorMsg: null
    }
  }

  validateTargetCountry = (targetCountry) => {
    if(!targetCountry) {
        return {
            validateStatus: 'error',
            errorMsg: 'Target Country may not be empty'                
        }
    } else if (targetCountry.length > 50) {
        return {
            validateStatus: 'error',
            errorMsg: `Target Country is too long (Maximum 50 characters allowed.)`
        }
    } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
          };            
    }
  }

  validateNumberOfLeads = (numberOfLeads) => {
    if(!numberOfLeads) {
        return {
            validateStatus: 'error',
            errorMsg: 'Number of leads may not be empty'                
        }
    } else if (numberOfLeads < 1) {
        return {
            validateStatus: 'error',
            errorMsg: `As least one lead`
        }
    } else if (numberOfLeads %1 != 0) {
      return {
        validateStatus: 'error',
        errorMsg: `Please input an integer`
      }
  } else {
        return {
            validateStatus: 'success',
            errorMsg: null,
          };            
    }
  }
  

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  setHtmlTemplate(htmlTemplate) {
    this.setState({
        emailTemplateHtml : {
            value: htmlTemplate
        }
    });
  }

  setJsonTemplate(emailTemplateJson) {
    this.setState({
      emailTemplateJson : {
            value: emailTemplateJson
        }
    });
  }


  _submit() {
    let currentStep = this.state.currentStep;
    if (this.state.emailStatus.value == 0) {

      const postCampaignDetailRequest = {
        campaignId: localStorage.getItem("campaignId"), 
        campaignName: this.state.campaignName.value, 
        emailSubject: this.state.emailSubject.value, 
        senderName: this.state.senderName.value, 
        senderEmail: this.state.senderEmail.value, 
        eventType: this.state.eventType.value, 
        campaignType: this.state.campaignType.value, 
        channel: this.state.channel.value, 
        conversionFocus: this.state.conversionFocus.value, 
        promotionPeriod: this.state.promotionPeriod.value, 
        commissionPercentage: this.state.commissionPercentage.value, 
        chargeOfClickThrough: this.state.chargeOfClickThrough.value, 
        targetCountry: this.state.targetCountry.value, 
        targetGender: this.state.targetGender.value, 
        targetArea: this.state.targetArea.value, 
        targetRadius: this.state.targetRadius.value, 
        targetLat: this.state.targetLat.value, 
        targetLng: this.state.targetLng.value, 
        numberOfLeads: this.state.numberOfLeads.value, 
        emailTemplateJson: this.state.emailTemplateJson.value, 
        emailTemplateHtml: this.state.emailTemplateHtml.value, 
        emailStatus: 1, 
      };


      postCampaignDetail(postCampaignDetailRequest)
      .then(response => {
        console.log(response);
        if (response.success){
          notification.success({
            message: 'Campaign',
            description: "This campaign is successfully submit!",
          });          
          
          this.setState({
            currentStep: currentStep,
            emailStatus: {
              value: 1
            },
          });

          this.loadCampaign();

        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });          
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
    } else {
      this.setState({
        currentStep: currentStep
      });
    }
    
  }

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;

    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 5 ? 6 : currentStep + 1;

    if (currentStep == 2) {
      const campaignNameState = this.validateCampaignName(this.state.campaignName.value);
      const emailSubjectState = this.validateEmailSubject(this.state.emailSubject.value);
      const senderNameState = this.validateSenderName(this.state.senderName.value);
      const senderEmailState = this.validateSenderEmail(this.state.senderEmail.value);

      this.setState({
        campaignName : {
          value: this.state.campaignName.value,
          ...campaignNameState
        },
        emailSubject : {
          value: this.state.emailSubject.value,
          ...emailSubjectState
        },
        senderName : {
          value: this.state.senderName.value,
          ...senderNameState
        },
        senderEmail : {
          value: this.state.senderEmail.value,
          ...senderEmailState
        }
      });

      if (campaignNameState.validateStatus != 'error'
          && emailSubjectState.validateStatus != 'error'
          && senderNameState.validateStatus != 'error'
          && senderEmailState.validateStatus != 'error'){
        this.setState({
          currentStep: currentStep
        });
      }

    } else if (currentStep == 4) {
      if (this.state.targetLat.value && this.state.targetLng.value) {
        this.setState({
          currentStep: currentStep
        });
      } else {
        alert('Please pin a target location on the map.');
      }
      

    } else if (currentStep == 5) {
      if (this.iFrameRef.current){
        this.iFrameRef.current.contentWindow.postMessage({
          "action": "loadPreview",
        },
          SERVER_URL + '/route/draft/'
        );
    
      } 

    } else if (currentStep == 6 && this.state.emailStatus.value == 0) {
      const postCampaignDetailRequest = {
        campaignId: localStorage.getItem("campaignId"), 
        campaignName: this.state.campaignName.value, 
        emailSubject: this.state.emailSubject.value, 
        senderName: this.state.senderName.value, 
        senderEmail: this.state.senderEmail.value, 
        eventType: this.state.eventType.value, 
        campaignType: this.state.campaignType.value, 
        channel: this.state.channel.value, 
        conversionFocus: this.state.conversionFocus.value, 
        promotionPeriod: this.state.promotionPeriod.value, 
        commissionPercentage: this.state.commissionPercentage.value, 
        chargeOfClickThrough: this.state.chargeOfClickThrough.value, 
        targetCountry: this.state.targetCountry.value, 
        targetGender: this.state.targetGender.value, 
        targetArea: this.state.targetArea.value, 
        targetRadius: this.state.targetRadius.value, 
        targetLat: this.state.targetLat.value, 
        targetLng: this.state.targetLng.value, 
        numberOfLeads: this.state.numberOfLeads.value, 
        emailTemplateJson: this.state.emailTemplateJson.value, 
        emailTemplateHtml: this.state.emailTemplateHtml.value, 
      };


      postCampaignDetail(postCampaignDetailRequest)
      .then(response => {
        console.log(response);
        if (response.success){
          notification.success({
            message: 'Campaign',
            description: "This campaign is successfully saved!",
          });          
          
          localStorage.setItem('campaignId', response.campaignId);
          this.loadCampaign();
          
          this.setState({
            currentStep: currentStep,
            campaignId: {
              value: response.campaignId
            },
          });


        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });          
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
    } else {
      this.setState({
        currentStep: currentStep
      });
    }
    
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  }

  // The "next" and "previous" button functions
  get previousButton() {
    let currentStep = this.state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button color="secondary float-left" onClick={this._prev}>
          Previous
        </Button>
      );
    }

    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 5) {
      return (
        <Button color="primary float-right" onClick={this._next}>
          Next
        </Button>
      );
    } else if (currentStep == 5 && this.state.emailStatus.value == 0) {
      return (
        <Button color="primary float-right" onClick={this._next}>
          Save and Next
        </Button>
      );
    } else if (currentStep == 5 && this.state.emailStatus.value > 0) {
      return (
        <Button color="primary float-right" onClick={this._next}>
          Next
        </Button>
      );
    }
    // ...else render nothing
    return null;
  }

  get submitButton() {
    let currentStep = this.state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 5 && this.state.emailStatus.value == 0) {
      return <Button color="primary float-right" onClick={this._submit}>Submit</Button>;
    }
    // ...else render nothing
    return null;
  }

  render() {
    return (
      <>
          <Card>
            <CardHeader>Campaign Detail</CardHeader>
            <CardBody>
              <CardTitle>
                <MultiStepProgressBar currentStep={this.state.currentStep} />
              </CardTitle>
              <CardText />
              <Step1
                currentStep={this.state.currentStep}
                handleInputChange={this.handleInputChange}
                validateCampaignName={this.validateCampaignName}
                validateEmailSubject={this.validateEmailSubject}
                validateSenderEmail={this.validateSenderEmail}
                validateSenderName={this.validateSenderName}
                campaign={this.state}
              />
              <Step2
                currentStep={this.state.currentStep}
                handleOptionChange={this.handleOptionChange}
                handleConversionFocusChange={this.handleConversionFocusChange}
                handlePromotionPeriodChange={this.handlePromotionPeriodChange}
                handleCommissionPercentageChange={this.handleCommissionPercentageChange}
                handleChargeOfClickThrough={this.handleChargeOfClickThrough}
                campaign={this.state}
              />
              <Step3
                currentStep={this.state.currentStep}
                handleOptionChange={this.handleOptionChange}
                handleInputChange={this.handleInputChange}
                validateTargetCountry={this.validateTargetCountry}
                validateNumberOfLeads={this.validateNumberOfLeads}
                handleTargetRadiusChange={this.handleTargetRadiusChange}
                handleCoordinateChange={this.handleCoordinateChange}
                campaign={this.state}

              />
              <Step4
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                email={this.state.password}
                campaign={this.state}
                iFrameRef = {this.iFrameRef}
                setJsonTemplate={this.setJsonTemplate}
                setHtmlTemplate={this.setHtmlTemplate}
                setCurrentStep={this.setCurrentStep}
              />
              <Step5
                currentStep={this.state.currentStep}
                campaign={this.state}
                
              />
              <Step6
                currentStep={this.state.currentStep}
                campaign={this.state}
              />
            </CardBody>
            <CardFooter>
              {this.previousButton}
              {this.nextButton}
              {this.submitButton}
            </CardFooter>
          </Card>
      </>
    );
  }
}

export default MasterForm;
