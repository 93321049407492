import React from "react";
import { FormGroup, Label} from "reactstrap";
import { Form, Input  } from 'antd';

const FormItem = Form.Item;
const Step1 = props => {
  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <>
    <FormGroup>
      <div className="row align-items-stretch">
            <div className="col-md-4">
                <Label>Campaign Name</Label>
            </div>
                            
            <div className="col-md-8">
            <FormItem 
                validateStatus={props.campaign.campaignName.validateStatus}
                help={props.campaign.campaignName.errorMsg}>
                <Input
                    type="text"
                    name="campaignName"
                    id="campaignName"
                    placeholder="Enter your Campaign Name"
                    value={props.campaign.campaignName.value} // Prop: The email input data
                    onChange={(event) => props.handleInputChange(event, props.validateCampaignName)} // Prop: Puts data into the state
                />
            </FormItem>
            </div>
        </div>

        <div className="row align-items-stretch">
            <div className="col-md-4">
                <Label>Email Subject</Label>
            </div>
                            
            <div className="col-md-8">
            <FormItem 
                validateStatus={props.campaign.emailSubject.validateStatus}
                help={props.campaign.emailSubject.errorMsg}>
                <Input
                    type="text"
                    name="emailSubject"
                    id="emailSubject"
                    placeholder="Enter your Email Subject"
                    value={props.campaign.emailSubject.value} // Prop: The email input data
                    onChange={(event) => props.handleInputChange(event, props.validateEmailSubject)} // Prop: Puts data into the state
                />
            </FormItem>
            </div>
        </div>

        <div className="row align-items-stretch">
            <div className="col-md-4">
                <Label>Sender Name</Label>
            </div>
                            
            <div className="col-md-8">
            <FormItem 
                validateStatus={props.campaign.senderName.validateStatus}
                help={props.campaign.senderName.errorMsg}>
                <Input
                    type="text"
                    name="senderName"
                    id="senderName"
                    placeholder="Enter the Sender Name"
                    value={props.campaign.senderName.value} // Prop: The email input data
                    onChange={(event) => props.handleInputChange(event, props.validateSenderName)} // Prop: Puts data into the state
                />
            </FormItem>
            </div>
        </div>

        <div className="row align-items-stretch" style={{display: 'none' }}>
            <div className="col-md-4">
                <Label>Sender Email</Label>
            </div>
                            
            <div className="col-md-8">
            <FormItem 
                validateStatus={props.campaign.senderEmail.validateStatus}
                help={props.campaign.senderEmail.errorMsg}>
                <Input
                    type="text"
                    name="senderEmail"
                    id="senderEmail"
                    placeholder="Enter the sender email"
                    value={props.campaign.senderEmail.value} // Prop: The email input data
                    onChange={(event) => props.handleInputChange(event, props.validateSenderEmail)} // Prop: Puts data into the state
                />
            </FormItem>
            </div>
        </div>

    </FormGroup>
    </>
  );
};

export default Step1;
