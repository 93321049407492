import React, { useRef, useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import { getProfile, postProfile } from '../util/APIUtils';
import { notification } from 'antd';


export function Profile() {
  const [businessName, setBusinessName] = useState();
  const [businessWebsite, setBusinessWebsite] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [email, setEmail] = useState();
  const [companyId, setCompanyId] = useState();

  useEffect(()=>{
    getProfile()
      .then(response => {
        console.log(response);
        if (response.success){
            setBusinessName(response.businessName);
            setBusinessWebsite(response.businessWebsite);
            setContactNumber(response.contactNumber);
            setContactPerson(response.contactPerson);
            setEmail(response.email);
            setCompanyId(response.companyId);
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
  }, [])


  const _submit = () => {
    const postProfileRequest = {
      businessName: businessName, 
      businessWebsite: businessWebsite, 
      contactNumber: contactNumber, 
      contactPerson: contactPerson, 
      email: email, 
    };


    postProfile(postProfileRequest)
    .then(response => {
      console.log(response);
      if (response.success){
        notification.success({
          message: 'Profile',
          description: "Your profile is successfully updated.",
        });          
      } else {
        notification.error({
          message: 'Profile',
          description: response.message || 'Sorry! Something went wrong. Please try again!'
        });          
      }
    }).catch(error => {
        notification.error({
            message: 'Profile',
            description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
    });
  
    
  }

  const businessNameHandler = event => {
    setBusinessName(event.target.value);
 };
  const businessWebsiteHandler = event => {
    setBusinessWebsite(event.target.value);
  };
  const contactNumberHandler = event => {
    setContactNumber(event.target.value);
  };
  const contactPersonHandler = event => {
    setContactPerson(event.target.value);
  };
  const emailHandler = event => {
    setEmail(event.target.value);
  };

  return (
    <div className="card" >
    
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="col-md-10">
        <h2 className="h3">Profile</h2>
      </div>

    </div>
        <div id="content">
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-10">
                   <div className="form-group row">
                    <span className="col-sm-3">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        Company Name
                      </label>
                    </span>
                    <span className="col-sm-9">
                      <input
                        className="form-control"
                        id="companyName"
                        name="companyName"
                        value={businessName}
                        placeholder="Company Name"
                        required="required"
                        onChange={businessNameHandler}
                      />
                    </span>
                    <p className="help-block text-danger" />
                  </div>
                  <div className="form-group row">
                    <span className="col-sm-3">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        Company Website
                      </label>
                    </span>
                    <span className="col-sm-9">
                      <input
                        className="form-control"
                        id="companyWebsite"
                        name="companyWebsite"
                        defaultValue=""
                        value={businessWebsite}
                        placeholder="Company Website"
                        required="required"
                        onChange={businessWebsiteHandler}
                      />
                    </span>
                    <p className="help-block text-danger" />
                  </div>
                  <div className="form-group row">
                    <span className="col-sm-3">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        Company Email
                      </label>
                    </span>
                    <span className="col-sm-9">
                      <input
                        className="form-control"
                        id="companyEmail"
                        type="email"
                        name="companyEmail"
                        value={email}
                        defaultValue=""
                        placeholder="Company Email"
                        required="required"
                        //onChange={emailHandler}
                        readonly
                      />
                    </span>
                    <p className="help-block text-danger" />
                  </div>
                  <div className="form-group row">
                    <span className="col-sm-3">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        Company Contact No.
                      </label>
                    </span>
                    <span className="col-sm-9">
                      <input
                        className="form-control"
                        id="companyContactNo"
                        name="companyContactNo"
                        defaultValue=""
                        value={contactNumber}
                        placeholder="Company Contact No."
                        required="required"
                        onChange={contactNumberHandler}
                      />
                    </span>
                    <p className="help-block text-danger" />
                  </div>
                  <div className="form-group row">
                    <span className="col-sm-3">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        Contact Person
                      </label>
                    </span>
                    <span className="col-sm-9">
                      <input
                        className="form-control"
                        id="contactPerson"
                        name="contactPerson"
                        defaultValue=""
                        value={contactPerson}
                        placeholder="Contact Person"
                        required="required"
                        onChange={contactPersonHandler}
                      />
                    </span>
                    <p className="help-block text-danger" />
                  </div>
                  <div className="form-group row">
                    <span className="col-sm-3">
                      <label style={{ display: "flex", alignItems: "center" }}>
                        Tracking ID
                      </label>
                    </span>
                    <span className="col-sm-9">
                      <input
                        className="form-control"
                        id="companyId"
                        name="companyId"
                        value={companyId}
                        defaultValue=""
                        placeholder="Tracking ID"
                        required="required"
                        //onChange={emailHandler}
                        readonly
                      />
                    </span>
                    <p className="help-block text-danger" />
                  </div>
                  <div className="form-group row justify-content-center">
                    <input
                      className="btn btn-primary btn-xl text-uppercase "
                      type="submit"
                      value="Update profile"
                      style={{ width: "50%" }}
                      onClick={_submit}
                    />
                  </div>
              </div>
              <div className="col-sm-1"></div>
                </div>
            </div>
        </div>
    </div>
  );
}