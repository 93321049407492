import React, { Component } from 'react';

import './css/Home.css';

class CookiesPolicy extends Component {

    render() {
        return (
            <div>
                <br/>
                <title>Cookies Policy</title>
                {/* <link rel="icon" type="image/x-icon" th:href="@{|${cdnResource}resources/assets/img/bilrost_y3.ico|}" />
                <link rel="alternate" href="https://bilrost.io/" hrefLang="x-default" /><link rel="alternate" href="https://bilrost.io/" hrefLang="en" />
                {/* End Google Tag Manager */}
                {/* Font Awesome icons (free version)*/}
                {/* Google fonts*/}

                <div className="container">
                    <div>
                    <h3>Cookie Policy</h3>
                    <p>In common with standard industry practice, Bilröst uses cookies to track site usage. A cookie is a small text file containing a unique identification number that is placed on the hard disk of your computer by a Web page server, enabling the Web server to identify your web browser and passively track its activities on that website. A cookie does not reveal any personally identifiable information that you have not otherwise disclosed. Cookies do not contain any executable code and cannot be used for infecting your computer with a virus. Most Web browsers automatically accept cookies but usually provide the option of configuring your browser to accept all cookies, reject all cookies, or notify you when a cookie is sent. You can change your settings in the preferences or options menu of your browser. If you choose to decline cookies, certain features of our Services may not be available to you.</p>
                    <p>Some parts of the Bilröst site use cookies to collect information about visitors’ use of the Web site and to facilitate return visits. The information collected from cookies is tracked to enhance security and to improve the functionality of the Web site by avoiding duplicate data entry, facilitating navigation, and increasing the relevance of content. Cookies on the site may collect the following information: a unique identifier, user preferences and profile information used to personalise the content that is shown, and membership information to access Bilröst’s online communities. Some cookies used by bilrost.io may remain on the user’s computer after they leave the Web site, but the majority are set to expire within 30 – 365 days(1). Cookies also serve as a convenience to users by creating a more streamlined login process. In the future, as we enable further customisation of the site, cookies will help in assuring that information provided to you will be the most relevant to your needs.</p>
                    <p>You then have the opportunity to accept or reject the cookie. Additionally, you may set your browser to refuse all cookies or accept only cookies returned to the originating servers. Users may generally disable the cookie feature on their browser without affecting their ability to use the site, except in some cases where cookies are used as an essential security feature necessary for transaction completion.</p>
                    <p>(1). If you set your browser to alert you to cookies being placed, a prompt screen requesting acceptance will describe information about the cookie, its duration, and the server it is being returned to.</p>
                    </div></div>
                </div>
        );
    }
}



export default CookiesPolicy;