import React, { useRef, useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import { Avatar, Tabs } from 'antd';
import { getSubscription, postSubscriptionCancel } from '../util/APIUtils';
import { notification } from 'antd';

export function Subscription() {
  const [businessName, setBusinessName] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [email, setEmail] = useState();
  const [total, setTotal] = useState();
  const [subscriptionStartedDate, setSubscriptionStartedDate] = useState();
  const [lastPaymentDate, setLastPaymentDate] = useState();
  const [userQuota, setUserQuota] = useState();

  const TabPane = Tabs.TabPane;

  const tabBarStyle = {
    textAlign: 'center'
  };

  
  const handleClick = () => {
    postSubscriptionCancel()
      .then(response => {
        console.log(response);
        if (response.success){
        notification.success({
          message: 'Notification',
          description: "Subscribe successfully.",
        });
        window.location.href = "/control_panel/subscription";
        } else {
        notification.error({
          message: 'Campaign',
          description: response.ErrorMsg
        });
        }
      }).catch(error => {
        notification.error({
          message: 'Campaign',
          description: error.ErrorMsg
        });
      });
      
  };

  useEffect(()=>{
    getSubscription()
      .then(response => {
        console.log(response);
        if (response.success){
            setBusinessName(response.businessName);
            setContactPerson(response.contactPerson);
            setEmail(response.email);
            setTotal(response.total);
            setSubscriptionStartedDate(response.subscriptionStartedDate);
            setLastPaymentDate(response.lastPaymentDate);
            setUserQuota(response.userQuota);
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
  }, [])

  return (
    <div className="card" >
    
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="col-md-10">
        <h2 className="h3">Subscription</h2>
      </div>

    </div>
    <Tabs defaultActiveKey="1" 
        animated={false}
        tabBarStyle={tabBarStyle}
        size="large"
        className="profile-tabs">
        <TabPane tab={`Information`} key="1">
            <div className="form-group row">
                <span className="col-sm-3">
                </span>
                <span className="col-sm-3" style={{textAlign: 'right'}}>
                    <p>Business Name:</p>
                    <p>Contact Person:</p>
                    <p>E-Billing Address:</p>
                    <p>Subscription Started Date:</p>
                    <p>Last Payment Date:</p>
                    <p>User Quota:</p>
                </span>
                <span className="col-sm-3">
                    <p>{businessName}</p>
                    <p>{contactPerson}</p>
                    <p>{email}</p>
                    {!subscriptionStartedDate && <p>&nbsp;</p>}<p>{subscriptionStartedDate}</p>
                    {!lastPaymentDate && <p>&nbsp;</p>}<p>{lastPaymentDate}</p>
                    <p>{userQuota}</p>
                </span>
                <span className="col-sm-3">
                </span>
            </div>

        </TabPane>
        <TabPane tab={`Unsubscribe`}  key="2" >
            <p className="form-group row justify-content-center">Please click the “UNSUBSCRIBE” button to complete the unsubscription.</p>
            <div className="form-group row justify-content-center" >
            <input
                className="btn btn-primary btn-xl text-uppercase "
                name="save"
                type="submit"
                value="Unsubscribe"
                style={{ width: "50%" }}
                onClick={handleClick}
                disabled={!subscriptionStartedDate}
            />
            </div>
        
        </TabPane>
    </Tabs>
    
    </div>
  );
}