import React, { Component } from 'react';
import { forgetPassword } from '../../util/APIUtils';
import './ForgetPassword.css';
import { 
    EMAIL_MAX_LENGTH
} from '../../constants';

import { Form, Input, Button, notification } from 'antd';
import bilrost_digital_y from './img/bilrost_digital_y.png';
import background from "./img/header-bg.jpg";
const FormItem = Form.Item;

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: {
                value: 'unsubmitted'
            },
            email: {
                value: ''
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
    
        const ForgetPasswordRequest = {
            email: this.state.email.value,
        };
        this.setState({
            submit: {
                status: 'submitted',
            }
        });
        forgetPassword(ForgetPasswordRequest)
        .then(response => {
            notification.success({
                message: 'Notification',
                description: "The reset password email is successfully sent!",
            });          
            this.props.history.push("/signin");
        }).catch(error => {
            notification.error({
                message: 'Notification',
                description: error.message || 'Sorry! Something went wrong. Please try again!'
            });
        });
    }

    isFormInvalid() {
        return !(this.state.email.validateStatus === 'success' &&
        this.state.submit.status === 'unsubmitted'
        );
    }

    render() {
        return (
            <div>
            <div style={{ backgroundImage: `url(${background})` }}>
                <nav class="navbar navbar-expand-lg navbar-dark navbar-shrink" id="mainNav">
                    <div class="container">
                        <a class="navbar-brand js-scroll-trigger" href="/"><img src={bilrost_digital_y} alt="centered image"/></a>
                    </div>
                </nav>
            <div className="signup-container">
                <h1 className="page-title" style={{ display: 'block',  color: 'white'}}>Forget Password</h1>
                <div className="signup-content">
                    <Form onSubmit={this.handleSubmit} className="signup-form">
                    <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Email</h5>
                        <FormItem 
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input 
                                size="large"
                                name="email" 
                                type="email" 
                                autoComplete="off"
                                placeholder="Your email"
                                value={this.state.email.value} 
                                onBlur={this.validateEmailAvailability}
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />    
                        </FormItem>
                        <FormItem>
                            <Button type="primary" 
                                htmlType="submit" 
                                size="large" 
                                className="signup-form-button"
                                disabled={this.isFormInvalid()} style={{ backgroundColor: '#fec810',  borderColor: '#fec503' }}>Send password reset email</Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
            <br/>
            </div>
             {/* Footer*/}
             <footer className="footer py-8">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3 text-lg-left">Copyright © Bilröst 2021</div>
                    <div className="col-lg-6 text-lg-right">
                    <a href="/private_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myPp">Privacy Policy</a>
                    <a href="/cookies_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Cookies Policy</a>
                    <a href="https://www.lab.bilrost.io" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Service</a>
                    <a href="https://www.lab.bilrost.io/trends" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Trends</a>
                    </div>
                </div>
                </div>
                {/* End of Term of use Prompt */}
            </footer>
         </div>
        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email may not be empty'                
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email not valid'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }

    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });    
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'success',
                errorMsg: null
            }, 
            submit: {
                status: 'unsubmitted',
                errorMsg: null
            }, 
        });

    }

}

export default ForgetPassword;