import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap/dist/css/bootstrap.css";
import { getCampaignList } from '../util/APIUtils';
import { notification, Table, Tag } from 'antd';
import {Link} from 'react-router-dom';


function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage);
}

function toPages(pages) {
  const results = [];

  for (let i = 1; i < pages; i++) {
    results.push(i);
  }

  return results;
}

const viewCampaign = (row) => {
  localStorage.setItem("campaignId", row.campaignId);
}
{/*
const columns = [
  {
    name: "Buttons",
    button: true,
    cell: row =>
      <Link to='/control_panel/campaign_detail'>
        <button
          onClick={() => viewCampaign(row)}
          className="btn btn-primary" 
        >
          Edit
        </button>
      </Link>
  },
  {
    name: "Campaign Name",
    selector: (row) => row.campaignName,
    sortable: true
  },
  {
    name: "Email Subject",
    selector: (row) => row.emailSubject,
    sortable: true
  },
  {
    name: "Event Type",
    selector: (row) => row.eventType,
    sortable: true
  },
  {
    name: "Campaign Type",
    selector: (row) => row.campaignType,
    sortable: true
  },
  {
    name: "Sender Name",
    selector: (row) => row.senderName,
    sortable: true
  },
  {
    name: "Sender Email",
    selector: (row) => row.senderEmail,
    sortable: true
  },
  {
    name: "Channel",
    selector: (row) => row.channel,
    sortable: true
  },
  {
    name: "UTM Campaign",
    selector: (row) => row.utmCampaign,
    sortable: true
  },
  {
    name: "Type",
    selector: (row) => row.type,
    sortable: true
  },
  {
    name: "Conversion Focus",
    selector: (row) => row.conversionFocus,
    sortable: true
  },
  {
    name: "Promotion Period",
    selector: (row) => row.promotionPeriod,
    sortable: true
  },
  {
    name: "Commission Percentage",
    selector: (row) => row.commissionPercentage,
    sortable: true
  },
  {
    name: "Email Status",
    selector: (row) => row.emailStatus,
    sortable: true
  },
  {
    name: "Created Dtm",
    selector: (row) => row.createdDtm,
    sortable: true
  },
  {
    name: "Updated Dtm",
    selector: (row) => row.updatedDtm,
    sortable: true
  },
  {
    name: "Sent Dtm",
    selector: (row) => row.sentDtm,
    sortable: true
  }
];

// RDT exposes the following internal pagination properties
const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage
}) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <nav>
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleBackButtonClick}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            Previous
          </button>
        </li>
        {pageItems.map((page) => {
          const className =
            page === currentPage ? "page-item active" : "page-item";

          return (
            <li key={page} className={className}>
              <button
                className="page-link"
                onClick={handlePageNumber}
                value={page}
              >
                {page}
              </button>
            </li>
          );
        })}
        <li className="page-item">
          <button
            className="page-link"
            onClick={handleNextButtonClick}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

const BootyCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check">
    <input
      htmlFor="booty-check"
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="form-check-label" id="booty-check" />
  </div>
));*/}

//const navigate = useNavigate();

export function CampaignList() {
  const [campaign, setCampaign] = useState();

  const newCampaign = event => {
    localStorage.removeItem("campaignId");
		localStorage.removeItem("emailStatus");
    localStorage.removeItem("emailTemplateJson");
    console.log(localStorage.getItem("campaignId"));
  }

  useEffect(()=>{
    getCampaignList()
      .then(response => {
        console.log(response);
        if (response.success){
          setCampaign(response.data);
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });

  
  }, [])

  const { Column, ColumnGroup } = Table;

  return (
    <div className="card" >
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <div className="col-md-10">
          <h2 className="h3">Campaigns</h2>
        </div>
        <div className="col-md-2">
          <Link onClick={newCampaign} to='/control_panel/campaign_detail'  className="btn btn-primary" style={{float: 'right'}}> Create New </Link>
        </div>
      </div>
    
    {/*<DataTable 
          columns={columns}
          data={campaign}
          defaultSortFieldID={1}
          pagination
          paginationComponent={BootyPagination}
          highlightOnHover
          //responsive={true}
        />*/}
      <Table 
        dataSource={campaign} 
        //scroll={{ x: true }}
      >
        <Column
          title=""
          key="action"
          render={(_, record) => (
            <div>
              <Link to='/control_panel/campaign_detail'>
                {(record.emailStatus=='Draft') && <button
                  onClick={() => viewCampaign(record)}
                  className="btn btn-primary">
                  <img src="/edit.svg" alt="Edit" width="20" height="20"/>
                </button>}
                {(record.emailStatus!='Draft') && <button
                  onClick={() => viewCampaign(record)}
                  className="btn btn-primary">
                  <img src="/view.svg" alt="View" width="20" height="20" />
                </button>}

              </Link>
            </div>
          )}
        />
        <Column title="Campaign Name" dataIndex="campaignName" key="campaignName" />
        <Column title="Email Subject" dataIndex="emailSubject" key="emailSubject" />
        {/*<Column title="Event Type" dataIndex="eventType" key="eventType" />*/}
        <Column title="Campaign Type" dataIndex="campaignType" key="campaignType" />
        {/*<Column title="Sender Name" dataIndex="senderName" key="senderName" />
        <Column title="Sender Email" dataIndex="senderEmail" key="senderEmail" />
        <Column title="Channel" dataIndex="channel" key="channel" />
        <Column title="UTM Campaign" dataIndex="utmCampaign" key="utmCampaign" />
        <Column title="Type" dataIndex="type" key="type" />
        <Column title="Conversion Focus" dataIndex="conversionFocus" key="conversionFocus" />*/}
        <Column title="Promotion Period" dataIndex="promotionPeriod" key="promotionPeriod" />
        <Column title="Commission Percentage" dataIndex="commissionPercentage" key="commissionPercentage" />
        <Column title="Charge of Click Through Percentage" dataIndex="chargeOfClickThrough" key="chargeOfClickThrough" />
        <Column title="Email Status" dataIndex="emailStatus" key="emailStatus" />
        <Column title="Created Dtm" dataIndex="createdDtm" key="createdDtm" />
        <Column title="Updated Dtm" dataIndex="updatedDtm" key="updatedDtm" />
        {/*<Column title="Sent Dtm" dataIndex="sentDtm" key="sentDtm" />*/}
            
      </Table>
  </div>
    
  );
}