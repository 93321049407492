import React, { Component } from 'react';
import { signup, checkUsernameAvailability, checkEmailAvailability } from '../../util/APIUtils';
import { Layout } from 'antd';
import './Signup.css';
import { Link } from 'react-router-dom';
import { 
    NAME_MIN_LENGTH, NAME_MAX_LENGTH, 
    USERNAME_MIN_LENGTH, USERNAME_MAX_LENGTH,
    EMAIL_MAX_LENGTH,
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH
} from '../../constants';

import { Form, Input, Button, notification } from 'antd';
import bilrost_digital_y from './img/bilrost_digital_y.png';
import background from "./img/header-bg.jpg";

const FormItem = Form.Item;
const { Header, Content } = Layout;


class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: {
                value: 'unsubmitted'
            },
            username: {
                value: ''
            },
            email: {
                value: ''
            },
            password: {
                value: ''
            },
            businessName: {
                value: ''
            },
            businessWebsite: {
                value: ''
            },
            contactPerson: {
                value: ''
            },
            contactNumber: {
                value: ''
            },
            acceptTnc: {
                value: 1
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateUsernameAvailability = this.validateUsernameAvailability.bind(this);
        this.validateEmailAvailability = this.validateEmailAvailability.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    toggleChange = () => {
        if (this.state.acceptTnc.value == 0){
            this.setState({
                'acceptTnc' : {
                    value: 1
                }
            });
        } else {
            this.setState({
                'acceptTnc' : {
                    value: 0
                }
            });
        }
        
        console.log(this.state.acceptTnc.value);
    }

    handleSubmit(event) {
        event.preventDefault();
    
        const signupRequest = {
            email: this.state.email.value,
            username: this.state.username.value,
            password: this.state.password.value,
            businessName: this.state.businessName.value,
            businessWebsite: this.state.businessWebsite.value,
            contactPerson: this.state.contactPerson.value,
            contactNumber: this.state.contactNumber.value,
            acceptTnc: this.state.acceptTnc.value
        };
        this.setState({
            submit: {
                status: 'submitted',
            }
        });
        signup(signupRequest)
        .then(response => {
            notification.success({
                message: 'Notification',
                description: "Thank you! You're successfully registered. Please Login to continue!",
            });          
            this.props.history.push("/signin");
        }).catch(error => {
            notification.error({
                message: 'Notification',
                description: error.message || 'Sorry! Something went wrong. Please try again!'
            });
        });
    }

    isFormInvalid() {
        return !(this.state.username.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success' &&
            this.state.password.validateStatus === 'success' &&
            this.state.businessName.validateStatus === 'success' &&
            this.state.businessWebsite.validateStatus === 'success' &&
            this.state.contactPerson.validateStatus === 'success' &&
            this.state.contactNumber.validateStatus === 'success' &&
            this.state.submit.status === 'unsubmitted'
        );
    }

    render() {
        return (
            <div>
            <div style={{ backgroundImage: `url(${background})` }}>
                <nav class="navbar navbar-expand-lg navbar-dark navbar-shrink" id="mainNav">
                        <div class="container">
                            <a class="navbar-brand js-scroll-trigger" href="/"><img src={bilrost_digital_y} alt="centered image"/></a>
                        </div>
                </nav>
                
                <div className="signup-container" style={{ maxWidth: '600px' }}>
                    <div class="text-left">
                        <h3 class="section-heading text-uppercase" style={{ color: 'white' }}>Welcome to out platform!</h3>
                        <h6 class="section-subheading text-muted">Find your people. Engage your customers. Build your brand. Do it all with Our Marketing Platform. Already have an account?  <Link to="/signin" style={{color:'#fed136'}}>Sign in now!</Link></h6>
                    </div>

                    <div className="signup-content">
                        <Form onSubmit={this.handleSubmit} className="signup-form">
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Username</h5>
                            <FormItem 
                                hasFeedback
                                validateStatus={this.state.username.validateStatus}
                                help={this.state.username.errorMsg}>
                                <Input 
                                    size="large"
                                    name="username" 
                                    autoComplete="off"
                                    placeholder="A unique username"
                                    value={this.state.username.value} 
                                    onBlur={this.validateUsernameAvailability}
                                    onChange={(event) => this.handleInputChange(event, this.validateUsername)} />    
                            </FormItem>
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Email</h5>
                            <FormItem 
                                hasFeedback
                                validateStatus={this.state.email.validateStatus}
                                help={this.state.email.errorMsg}>
                                <Input 
                                    size="large"
                                    name="email" 
                                    type="email" 
                                    autoComplete="off"
                                    placeholder="Your email"
                                    value={this.state.email.value} 
                                    onBlur={this.validateEmailAvailability}
                                    onChange={(event) => this.handleInputChange(event, this.validateEmail)} />    
                            </FormItem>
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Password</h5>
                            <FormItem 
                                validateStatus={this.state.password.validateStatus}
                                help={this.state.password.errorMsg}>
                                <Input 
                                    size="large"
                                    name="password" 
                                    type="password"
                                    autoComplete="off"
                                    placeholder="A password between 6 to 20 characters" 
                                    value={this.state.password.value} 
                                    onChange={(event) => this.handleInputChange(event, this.validatePassword)} />    
                            </FormItem>
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Business Name</h5>
                            <FormItem class="section-heading text-uppercase" style={{ display: 'block',  color: 'white'}}
                                validateStatus={this.state.businessName.validateStatus}
                                help={this.state.businessName.errorMsg}>
                                <Input 
                                    size="large"
                                    name="businessName"
                                    autoComplete="off"
                                    placeholder="Your Business Name"
                                    value={this.state.businessName.value} 
                                    onChange={(event) => this.handleInputChange(event, this.validateBusinessName)} />    
                            </FormItem>
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Business Website</h5>
                            <FormItem class="section-heading text-uppercase" style={{ display: 'block',  color: 'white'}}
                                validateStatus={this.state.businessWebsite.validateStatus}
                                help={this.state.businessWebsite.errorMsg}>
                                <Input 
                                    size="large"
                                    name="businessWebsite"
                                    autoComplete="off"
                                    placeholder="Your Business Website"
                                    value={this.state.businessWebsite.value} 
                                    onChange={(event) => this.handleInputChange(event, this.validateBusinessWebsite)} />    
                            </FormItem>
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Contact Person</h5>
                            <FormItem class="section-heading text-uppercase" style={{ display: 'block',  color: 'white'}}
                                validateStatus={this.state.contactPerson.validateStatus}
                                help={this.state.contactPerson.errorMsg}>
                                <Input 
                                    size="large"
                                    name="contactPerson"
                                    autoComplete="off"
                                    placeholder="Name of the Contact Person"
                                    value={this.state.contactPerson.value} 
                                    onChange={(event) => this.handleInputChange(event, this.validateContactPerson)} />    
                            </FormItem>
                            <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>Contact Number</h5>
                            <FormItem class="section-heading text-uppercase" style={{ display: 'block',  color: 'white'}}
                                validateStatus={this.state.contactNumber.validateStatus}
                                help={this.state.contactNumber.errorMsg}>
                                <Input 
                                    size="large"
                                    name="contactNumber"
                                    autoComplete="off"
                                    placeholder="Contact Number"
                                    value={this.state.contactNumber.value} 
                                    onChange={(event) => this.handleInputChange(event, this.validateContactNumber)} />    
                            </FormItem>

                            <FormItem>
                            <table >
                                <tr>
                                    <td><Input 
                                    type="checkbox" 
                                    name="acceptTnc" 
                                    value={this.state.acceptTnc} 
                                    onChange={this.toggleChange}
                                    style={{"-webkit-text-align":"center" }}/></td>
                                    <td><label className="section-heading text-uppercase" style={{"WebkitTextAlign":"left","textAlign":"left", "verticalAlign": "middle", color: 'white' }}> &nbsp;&nbsp;I don't want to receive emails about new products or special offers.</label></td>
                                </tr>
                            </table>
                            </FormItem>
                            
                            <div className="form-group row">
                                <h6 className="section-subheading text-muted"><b>By clicking the "Sign Up" button, you are creating an account on our platform, and you agree to our <a style={{color:'#fed136'}} className="mr-3" id="myTou2" href="/cookies_policy" target="_blank" >Cookie Policy</a> and <a style={{color:'#fed136'}} className="mr-3" id="myPp2" href="/private_policy" target="_blank" >Privacy Policy</a>.</b></h6>
                            </div>

                            <FormItem>
                                <Button type="primary" 
                                    htmlType="submit" 
                                    size="large" 
                                    className="signup-form-button btn btn-primary btn-xl text-uppercase"
                                    style={{ backgroundColor: '#fec810',  borderColor: '#fec503' }}
                                    disabled={this.isFormInvalid()}>Sign up</Button>
                            </FormItem>

                          
                            
                        </Form>
                        <br/>
                    </div>

                </div>
            </div>
                {/* Footer*/}
                <footer className="footer py-8">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 text-lg-left">Copyright © Bilröst 2021</div>
                        <div className="col-lg-6 text-lg-right">
                        <a href="/private_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myPp">Privacy Policy</a>
                        <a href="/cookies_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Cookies Policy</a>
                        <a href="https://www.lab.bilrost.io" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Service</a>
                        <a href="https://www.lab.bilrost.io/trends" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Trends</a>
                        </div>
                    </div>
                    </div>
                    {/* End of Term of use Prompt */}
                </footer>
            </div>
        );
    }

    // Validation Functions

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email may not be empty'                
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email not valid'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }

    validateUsername = (username) => {
        if(username.length < USERNAME_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Username is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`
            }
        } else if (username.length > USERNAME_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Username is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: null,
                errorMsg: null
            }
        }
    }

    validateUsernameAvailability() {
        // First check for client side errors in username
        const usernameValue = this.state.username.value;
        const usernameValidation = this.validateUsername(usernameValue);

        if(usernameValidation.validateStatus === 'error') {
            this.setState({
                username: {
                    value: usernameValue,
                    ...usernameValidation
                }
            });
            return;
        }

        this.setState({
            username: {
                value: usernameValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkUsernameAvailability(usernameValue)
        .then(response => {
            if(response.available) {
                this.setState({
                    username: {
                        value: usernameValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }
                });
            } else {
                this.setState({
                    username: {
                        value: usernameValue,
                        validateStatus: 'error',
                        errorMsg: 'This username is already taken'
                    }
                });
            }
        }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                username: {
                    value: usernameValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }

    validateEmailAvailability() {
        // First check for client side errors in email
        const emailValue = this.state.email.value;
        const emailValidation = this.validateEmail(emailValue);

        if(emailValidation.validateStatus === 'error') {
            this.setState({
                email: {
                    value: emailValue,
                    ...emailValidation
                }
            });    
            return;
        }

        this.setState({
            email: {
                value: emailValue,
                validateStatus: 'validating',
                errorMsg: null
            }
        });

        checkEmailAvailability(emailValue)
        .then(response => {
            if(response.available) {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'success',
                        errorMsg: null
                    }, submit: {
                        status: 'unsubmitted',
                        errorMsg: null
                    }
                });
            } else {
                this.setState({
                    email: {
                        value: emailValue,
                        validateStatus: 'error',
                        errorMsg: 'This Email is already registered'
                    }
                });
            }
        }).catch(error => {
            // Marking validateStatus as success, Form will be recchecked at server
            this.setState({
                email: {
                    value: emailValue,
                    validateStatus: 'success',
                    errorMsg: null
                }
            });
        });
    }

    validatePassword = (password) => {
        if(password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Password is too short (Minimum ${PASSWORD_MIN_LENGTH} characters needed.)`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Password is too long (Maximum ${PASSWORD_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };            
        }
    }

    
    validateBusinessName = (businessName) => {
        if(!businessName) {
            return {
                validateStatus: 'error',
                errorMsg: 'Business Name may not be empty'                
            }
        } else if (businessName.length > NAME_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Business Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    validateBusinessWebsite = (businessWebsite) => {
        if(!businessWebsite) {
            return {
                validateStatus: 'error',
                errorMsg: 'Business Website may not be empty'                
            }
        } else if (businessWebsite.length > NAME_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `BusinessWebsite is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    validateContactPerson = (contactPerson) => {
        if(!contactPerson) {
            return {
                validateStatus: 'error',
                errorMsg: 'Contact Person may not be empty'                
            }
        } else if (contactPerson.length > NAME_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Contact Person is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    validateContactNumber = (contactNumber) => {
        if(!contactNumber) {
            return {
                validateStatus: 'error',
                errorMsg: 'Contact Number may not be empty'                
            }
        } else if (contactNumber.length > NAME_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Contact Number is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }
}

export default Signup;