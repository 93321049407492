import React, { useRef, useEffect, useState } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { SERVER_URL } from '../constants';


const Step4 = props => {
  useEffect(() => {
    window.addEventListener("message", function (e) {
      if (e.origin !== SERVER_URL) return;

      if (e.data.action == 'loadTemplate') {
          console.log('clicking patent loadTemplate');
          console.log(e.data);
          if (props.iFrameRef.current){
            props.iFrameRef.current.contentWindow.postMessage({
              "action": "loadTemplate",
              "data": localStorage.getItem('emailTemplateJson')
              //props.campaign.emailTemplateJson.value
            },
            SERVER_URL + '/route/draft/'
            );
        
          } 
        
      } else if (e.data.action == 'loadPreview') {
          console.log('clicking patent loadPreview');
          console.log(e.data);
          props.setJsonTemplate(e.data.json);
          props.setHtmlTemplate(e.data.html);
          props.setCurrentStep(5);

      }
      
    });
  }, []);
  
  return (
    <div style={{display: props.currentStep == 4 ? 'block' : 'none' }}>
      <iframe
          ref={props.iFrameRef}
          src={SERVER_URL + '/route/draft/'}
          width="100%"
          height="800"
      ></iframe>

      <br />
      <br />

    </div>
  );
};

export default Step4;
