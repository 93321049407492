import React from "react";
import { FormGroup, Label } from "reactstrap";
import '@coreui/coreui/dist/css/coreui.min.css'
import { CButtonGroup, CFormCheck, CSwitch, CButton } from '@coreui/react';
import { Form, Input, Switch  } from 'antd';
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const FormItem = Form.Item;

const Step2 = props => {
  if (props.currentStep !== 2) {
    return null;
  }

  const style = { width: 600, margin: 50 };

  function log(value) {
    console.log(value); //eslint-disable-line
  }

  function onSliderChange(value) {
    this.setState(
      {
        value
      },
      () => {
        console.log(this.state.value);
      }
    );
  };




  
  return (
    <>
      <FormGroup>
        <div className="row align-items-stretch">
          <div className="col-md-4">
              <Label>The Event</Label>
          </div>         
          <div className="col-md-8">
          <FormItem>
              <CButtonGroup role="group" aria-label="Basic checkbox toggle button group"
              onChange={(event) => props.handleOptionChange(event)}
              >
              <CFormCheck
                type="radio"
                button={{ color: 'info', variant: 'outline'}}
                checked={'onlineEvent' === props.campaign.eventType.value}
                name="eventType"
                id="onlineEvent"
                autoComplete="off"
                label="Online Event"
              />
              {/*<CFormCheck
                type="radio"
                button={{ color: 'info', variant: 'outline' }}
                checked={'offlineEvent' === props.campaign.eventType.value}
                name="eventType"
                id="offlineEvent"
                autoComplete="off"
                label="Offline Event"
              />*/}
            </CButtonGroup>
          </FormItem>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div className="col-md-4">
              <Label>The Campaign</Label>
          </div>         
          <div className="col-md-8">
          <FormItem>
              <CButtonGroup role="group" aria-label="Basic checkbox toggle button group"
              onChange={(event) => props.handleOptionChange(event)}
              >
              <CFormCheck
                type="radio"
                button={{ color: 'info', variant: 'outline'}}
                checked={'purchase' === props.campaign.campaignType.value}
                name="campaignType"
                id="purchase"
                autoComplete="off"
                label="Purchase"
              />
              <CFormCheck
                type="radio"
                button={{ color: 'info', variant: 'outline' }}
                checked={'lead' === props.campaign.campaignType.value}
                name="campaignType"
                id="lead"
                autoComplete="off"
                label="Lead"
              />
              {/*<CFormCheck
                type="radio"
                button={{ color: 'info', variant: 'outline' }}
                checked={'event' === props.campaign.campaignType.value}
                name="campaignType"
                id="event"
                autoComplete="off"
                label="Event"
              />*/}
            </CButtonGroup>
          </FormItem>
          </div>
        </div>

        <div className="row align-items-stretch">
          <div className="col-md-4">
              <Label>Channel</Label>
          </div>         
          <div className="col-md-8">
          <FormItem>
              <CButtonGroup role="group" aria-label="Basic checkbox toggle button group"
              onChange={(event) => props.handleOptionChange(event)}
              >
              <CFormCheck
                type="radio"
                button={{ color: 'info', variant: 'outline'}}
                checked={'email' === props.campaign.channel.value}
                name="channel"
                id="email"
                autoComplete="off"
                label="Email"
              />
            </CButtonGroup>
          </FormItem>
          </div>
        </div>

        {false && <div className="row align-items-stretch">
          <div className="col-md-4">
              <Label>Conversion Focus</Label>
          </div>         
          <div className="col-md-8">
          <Switch
          checked={props.campaign.conversionFocus.value}
          onChange={(event) => props.handleConversionFocusChange(event)} 
          />
          </div>
        </div>}

        <div className="row align-items-stretch">
          <div className="col-md-4">
              <Label>Promotion Peroid</Label>
          </div>         
          <div className="col-md-1">
              <Label>48 hours</Label>
          </div>
          <div className="col-md-6">
            
            <Slider
              name="promotionPeriod"
              min={2}
              max={30}
              value={props.campaign.promotionPeriod.value}
              onChange={(event) => props.handlePromotionPeriodChange(event)} 
              railStyle={{height: 5}}
              handleStyle={{
                height: 28,
                width: 28,
                marginLeft: 0,
                marginTop: -14,
                backgroundColor: "#0074d9",
                border: 10
              }}
              trackStyle={{
                background: "none"
              }}
            />
            <div style={{ margin: 5 }}>
             <p>{props.campaign.promotionPeriod.value} days</p>
            </div>
          </div>
          <div className="col-md-1">
              <Label>30 Days</Label>
          </div>
        </div>

        {('purchase' === props.campaign.campaignType.value) && <div className="row align-items-stretch" style={{ verticalAlign: 'middle' }}>
          <div className="col-md-4">
              <Label>Commission Percentage</Label>
          </div>         
          <div className="col-md-1" style={{ verticalAlign: 'middle' }}>
              <Label style={{ verticalAlign: 'middle' }}>15%</Label>
          </div>
          <div className="col-md-6">
            
            <Slider
              name="commissionPercentage"
              min={15}
              max={30}
              value={props.campaign.commissionPercentage.value}
              onChange={(event) => props.handleCommissionPercentageChange(event)} 
              railStyle={{height: 5}}
              handleStyle={{
                height: 28,
                width: 28,
                marginLeft: 0,
                marginTop: -14,
                backgroundColor: "#0074d9",
                border: 10
              }}
              trackStyle={{
                background: "none"
              }}
            />
            <div style={{ margin: 5,  verticalAlign: 'middle' }}>
            <p>{props.campaign.commissionPercentage.value} %</p>
            </div>
          </div>
          <div className="col-md-1">
              <Label>30%</Label>
          </div>
        </div>}
          
        {('lead' === props.campaign.campaignType.value) && <div className="row align-items-stretch" style={{ verticalAlign: 'middle' }}>
          <div className="col-md-4">
              <Label>Charge of Click Through</Label>
          </div>         
          <div className="col-md-1" style={{ verticalAlign: 'middle' }}>
              <Label style={{ verticalAlign: 'middle' }}>1 Click</Label>
          </div>
          <div className="col-md-6">
            
            <Slider
              name="chargeOfClickThrough"
              min={1}
              max={5}
              value={props.campaign.chargeOfClickThrough.value}
              onChange={(event) => props.handleChargeOfClickThrough(event)} 
              railStyle={{height: 5}}
              handleStyle={{
                height: 28,
                width: 28,
                marginLeft: 0,
                marginTop: -14,
                backgroundColor: "#0074d9",
                border: 10
              }}
              trackStyle={{
                background: "none"
              }}
            />
            <div style={{ margin: 5,  verticalAlign: 'middle' }}>
            <p>{props.campaign.chargeOfClickThrough.value} Clicks</p>
            </div>
          </div>
          <div className="col-md-1">
              <Label>5 Clicks</Label>
          </div>
        </div>}

      </FormGroup>
      
      
    </>
  );
};

export default Step2;
