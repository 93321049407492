import React, { useRef, useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';

export function Audience() {
  const [campaign, setCampaign] = useState();

  return (
    <div className="card" >
    
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="col-md-10">
        <h2 className="h3">Audience</h2>
      </div>

    </div>
        <div id="content">
            <div className="container-fluid">
                <div className="row">
                <h7>Bilrost is one of the best affiliate marketing platforms, with the innovation and latest technology our platform gives you the best
                solution to transform your network into valuable assert, with us our cloud solution transform your digital assets to revenue, sign up free
                now. Contact us for further information and integration requirements.</h7>
                </div>
                <br/>
                <div className="row">
                    <div className="form-group row">
                        <div className="col-sm-4">
                            <span className="label" style={{ color: "black" }}>
                                Customer List
                            </span>
                            <br />
                            <span className="secondary-label" style={{ color: "grey" }}>
                                CSV only, Limit 100 MB
                            </span>
                        </div>
                        <span className="col-sm-4">
                        <input
                            className="btn btn-primary btn-xl text-uppercase"
                            name="save"
                            type="submit"
                            value="UPLOAD"
                            style={{ width: "90%" }}
                        />
                        </span>
                        <span className="col-sm-4">
                        <input
                            className="btn btn-primary btn-xl text-uppercase"
                            name="save"
                            type="submit"
                            value="Export CSV Template Format"
                            style={{ width: "90%" }}
                        />
                        </span>
                    </div>


                    <div className="form-group row">
                        <span className="col-sm-4">
                        <span className="label" style={{ color: "black" }}>
                            Uploaded Customer List
                        </span>
                        <br />
                        <span className="secondary-label" style={{ color: "grey" }}>
                            500 x 250 Limit 2 MB
                        </span>
                        </span>
                        <span className="col-sm-4">
                        <input
                            className="btn btn-primary btn-xl text-uppercase"
                            name="save"
                            type="submit"
                            value="EXPORT"
                            style={{ width: "90%" }}
                        />
                        </span>
                        <span className="col-sm-4">
                        </span>

                    </div>
                    </div>
                </div>
            </div>
    </div>
  );
}