import React, { useRef, useEffect, useState } from 'react';
import { getProfile, getSubscription, postSubscription } from '../util/APIUtils';
import { notification, Switch } from 'antd';

import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import './checkout.css';




const ButtonWrapper = ({ type }) => {

	const [{ options }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

	return (<PayPalButtons
		createSubscription={(data, actions) => {
			return actions.subscription
				.create({
					plan_id: "P-8GX22229DW3200538MJH6QNA",
				})
				.then((orderId) => {
					// Your code here after create the order
					console.log('order id = ' + orderId);
					return orderId;
				});
		}}
		onApprove={(data, actions) =>  {
			postSubscription("P-8GX22229DW3200538MJH6QNA", data.subscriptionID)
			.then(response => {
			console.log(response);
			if (response.success){
				notification.success({
					message: 'Notification',
					description: "Subscribe successfully.",
				});
				window.location.href = "/subscribe/success";
			} else {
				notification.error({
				message: 'Campaign',
				description: response.message || 'Sorry! Something went wrong. Please try again!'
				});
				window.location.href = "/subscribe/fail";
			}
			}).catch(error => {
				notification.error({
					message: 'Campaign',
					description: error.message || 'Sorry! Something went wrong. Please try again!'
				});
				window.location.href = "/subscribe/fail";
			});
		}}
		onError={(data, actions) =>  {
			window.location.href = "/subscribe/fail";
		}}
		
		style={{
			label: "subscribe",
		}}
	/>);
}

export default function Checkout() {
	const [subscriptionStartedDate, setSubscriptionStartedDate] = useState();

  const [businessName, setBusinessName] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [email, setEmail] = useState();

	useEffect(() => {
		getSubscription()
		.then(response => {
		  console.log(response);
		  if (response.success){
			  setSubscriptionStartedDate(response.subscriptionStartedDate);
		  } else {
			notification.error({
			  message: 'Campaign',
			  description: response.message || 'Sorry! Something went wrong. Please try again!'
			});
		  }
		}).catch(error => {
			notification.error({
				message: 'Campaign',
				description: error.message || 'Sorry! Something went wrong. Please try again!'
			});
		});

    getProfile()
      .then(response => {
        console.log(response);
        if (response.success){
            setBusinessName(response.businessName);
            setContactPerson(response.contactPerson);
            setEmail(response.email);
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });

	  }, []);
  
	if (subscriptionStartedDate) {
		return (
			<div>
				You have already subscribed a plan.
			</div>
		);
	} else {
		return (
			<body>
			<div>
			<nav style={{"background-color":"#fdd017", padding: "10px 50px"}}>
				<div className="ml-auto"><h6 style={{"-webkit-text-align":"center","text-align":"center","color":"white"}}>* You will be charged a plan rate of £189 now (Your new plan will start today), then £189 every month until cancel this subscription</h6></div>
  			</nav>
			
			
			  <div className="wrapper">
    <br/>
    <div className="h5 large"><img src="favicon.ico" alt="Bilrost" width={30} height={30} /> <label style={{"vertical-align":"middle"}}>Checkout</label></div>
    <div className="row">
      <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-0 offset-md-2 offset-sm-1">
        <div id="details" className="bg-white rounded pb-5">
          <div className="h5 font-weight-bold text-primary"> Checkout information </div>
          <div className="text-muted"> <span className="fas fa-lock" /> Your subscription payment is fully protected by PayPal </div>
          <br />
          <div className="row">
            <div className="col-lg-6">
              <div className="display-5"> <label>Business Name</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group"> <label className="text-muted">{businessName}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="display-5"> <label>Contact Person</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group"> <label className="text-muted">{contactPerson}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="display-5"> <label>Contact Email</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group"> <label className="text-muted">{email}</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="display-5"> <label>E-Billing Address</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group"> <label className="text-muted">{email}</label>
              </div>
            </div>
          </div>
        </div>
        <div id="address" className="bg-light rounded mt-3">
          <div className="h5 font-weight-bold text-primary"> Payment method </div>
          <div className="text-muted"> <span className="fas fa-lock" /> All transaction are secured and encrypted </div>
          <PayPalScriptProvider
				options={{
					"client-id": "AeOIJGB2qt_NLyMXagtwR27Pvhs9nTYPYwj4VfDQrM-nhEit2Xrvli8IkijbVap_LrJ1uRkOzbicqI7Y",
					components: "buttons",
					intent: "subscription",
					vault: true,
				}}
			>
				<ButtonWrapper type="subscription" />
		  </PayPalScriptProvider>

	
        </div>
      </div>
      <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-0 offset-md-2 offset-sm-1 pt-lg-0 pt-3">
        <div id="cart" className="bg-white rounded">
          <div className="d-flex justify-content-between align-items-center">
            <div className="h6">Purchase Summary</div>
            <div className="h6"> Billed in British Pound</div>
          </div>
          <div className="d-flex jusitfy-content-between align-items-center pt-3 pb-2 border-bottom">
            <div className="item pr-2"> <img src="/mail.png" alt width={80} height={80} />
              <div className="number">1</div>
            </div>
            <div className="d-flex flex-column px-3"> <b className="h5">Standard Plan</b> <p className="h7 text-primary">Monthly Subscription</p> </div>
            <div className="ml-auto"> <b className="h5">£189</b> </div>
          </div>
          <div className="my-3"> {/*<input type="text" class="w-100 form-control text-center" placeholder="Gift Card or Promo Card">*/} </div>
          <div className="d-flex align-items-center">
            <div className="display-5">Subtotal</div>
            <div className="ml-auto font-weight-bold">£189</div>
          </div>
          
          <div className="d-flex align-items-center py-2">
            <div className="display-5">Total</div>
            <div className="ml-auto d-flex">
              <div className="text-primary text-uppercase px-3">GBP</div>
              <div className="font-weight-bold">£189</div>
            </div>
          </div>
        </div>
        <p className="text-muted">Need help with an order?</p>
        <p className="text-muted">Contact us:&nbsp;<a href="mailto: hello@bilrost.com" className="text-danger">hello@bilrost.com</a></p>
        <div className="row pt-lg-3 pt-2 buttons mb-sm-0 mb-2">
          {/*<div className="col-md-6">
            <div className="btn text-uppercase">back to shopping</div>
          </div>
          <div className="col-md-6 pt-md-0 pt-3">
            <div className="btn text-white ml-auto"> <span className="fas fa-lock" /> Continue to Shopping </div>
      </div>*/}
        </div>
        <p className="text-muted">Your recepit will be sent to:&nbsp;<a href="#" className="text-danger">{email}</a></p>

        <hr />        
          <p className="text-muted">By clicking to subscribe us, I understand that I am enrolling in a subscription plan and I authorize Bilröst to charge my payment method each month at the then-current list price unless I cancel. I can unsubscribe anytime by going to my Subscription page in the Account Settings.</p>
          <p className="text-muted">Actual price may vary. Prices displayed are estimates based on current exchange rates. Exact amount charged will vary and depends on the exchange rate at the time of payment processing. Prices displayed exclude any applicable taxes and fees, which will be added to the cost of all services purchased. For exact base pricing please refer to prices listed in GBP.</p>
        {/*<div className="text-muted d-flex align-items-center">
          <div style={{"width":"20%"}} />
          <div style={{"width":"20%"}}><a href="/private_policy" target="_blank" className="text-danger">Return policy</a></div>
          <div style={{"width":"20%"}}><a href="/private_policy" target="_blank" className="text-danger">Privacy policy</a></div>
          <div style={{"width":"20%"}}><a href="/cookies_policy" target="_blank" className="text-danger">Terms of service</a></div>
        </div>*/}

      </div>
    </div>
    <hr />    
  </div> 
			

			</div>
			</body>
		);
	}
}