import React, { Component } from 'react';

import bilrost_digital_y from '../user/login/img/bilrost_digital_y.png';
import background from "../user/login/img/header-bg.jpg";
import '../user/login/Login.css';
import './css/source-code-box.css';

class Unsubscript extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }
    


    render() {
        return (
            <div>
            <div style={{ backgroundImage: `url(${background})` }}>
                <nav class="navbar navbar-expand-lg navbar-dark navbar-shrink" id="mainNav">
                    <div class="container">
                        <a class="navbar-brand js-scroll-trigger" href="/"><img src={bilrost_digital_y} alt="centered image"/></a>
                    </div>
                </nav>

                <section className="page-section" id="contact">
          <div className="container">
            <div className="text-left">
              <h2 className="section-heading text-uppercase">Developer</h2>
              <h5 className="section-subheading text-muted">Please put the following java script to the header of all the pages of your website, and please replace ID-XXXXXXXX with the Tracking ID in your account profile page:</h5>
            </div>
            <br />
            <div className="text-left">
              {/* HTML Box */}
              <div className="codeheader" id="codeheader_html">Please put the following java script to the header of all the pages of your website:</div>
              <div id="codebox" style={{backgroundColor: 'white'}}>
                <code data-language="html" wrap="hard" style={{textAlign: 'left', width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>&lt;script&gt;<br />!function(e,t,n,o,i,p,s){'{'}e[o]||((i=e[o]=function(){'{'}i.process?i.process.apply(i,arguments):i.queue.push(arguments){'}'}).queue=[],i.t=+new Date,(p=t.createElement(n)).async=1,p.src="https://t.bilrost.io/bilrostpixel.min.js?t="+864e5*Math.ceil(new Date/864e5),(s=t.getElementsByTagName(n)[0]).parentNode.insertBefore(p,s)){'}'}(window,document,"script","opix"),opix("init","ID-XXXXXXXX"),opix("event","pageload");<br />&lt;/script&gt;</code>
              </div>
            </div>
            <br />
            <div className="text-left">
              <h4 className="section-heading text-uppercase">Standard Events</h4>
              <h6 className="section-subheading text-muted">You must track the following standard events and include a parameter object that contains the specified properties.</h6>
            </div>
            <br />
            <div className="text-left">
              {/* HTML Box */}
              <div className="codeheader" id="codeheader_html">Product Detail: Track the following standard event when a visitor views your product detail page:</div>
              <div id="codebox" style={{backgroundColor: 'white'}}>
                <code data-language="html" wrap="hard" style={{textAlign: 'left', width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>
                  <pre>opix('event', 'product_detail',{"\n"}&nbsp; &nbsp; {"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_id: 'product_id',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_name: 'product_name',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; category_name: 'category' // or set to empty string{"\n"}&nbsp; &nbsp; {"}"}{"\n"});</pre>
                </code>
              </div>
            </div>
            <br />
            <div className="text-left">
              {/* HTML Box */}
              <div className="codeheader" id="codeheader_html">Add to cart button: Track the following standard event when a visitor add a product to the shopping cart:</div>
              <div id="codebox" style={{backgroundColor: 'white'}}>
                <code data-language="html" wrap="hard" style={{textAlign: 'left', width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>
                  <pre>opix('event', 'add_to_cart',{"\n"}&nbsp; &nbsp; {"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_id: 'product_id',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_name: 'product_name',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; category_name: 'category', // or set to empty string{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; quality: 2,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; unit_price: 10,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; currency: 'USD'{"\n"}&nbsp; &nbsp; {"}"}{"\n"});</pre>
                </code>
              </div>
            </div>
            <br />
            <div className="text-left">
              {/* HTML Box */}
              <div className="codeheader" id="codeheader_html">Shopping Cart Page: Track the following standard event when a visitor views your shopping cart page:</div>
              <div id="codebox" style={{backgroundColor: 'white'}}>
                <code data-language="html" wrap="hard" style={{textAlign: 'left', width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>
                  <pre>opix('event', 'shopping_cart_page',{"\n"}&nbsp; &nbsp; {"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; products: [{"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_id: 'product_id',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_name: 'product_name',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; category_name: 'category', // or set to empty string{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; quality: 2,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; unit_price: 10,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; currency: 'USD'{"}"}],{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; total_price: 20{"\n"}&nbsp; &nbsp; {"}"}{"\n"});</pre>
                </code>
              </div>
            </div>
            <br />
            <div className="text-left">
              {/* HTML Box */}
              <div className="codeheader" id="codeheader_html">Checkout page: Track the following standard event when a visitor views your checkout page:</div>
              <div id="codebox" style={{backgroundColor: 'white'}}>
                <code data-language="html" wrap="hard" style={{textAlign: 'left', width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>
                  <pre>opix('event', 'checkout',{"\n"}&nbsp; &nbsp; {"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; products: [{"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_id: 'product_id',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_name: 'product_name',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; category_name: 'category', // or set to empty string{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; quality: 2,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; unit_price: 10,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; currency: 'USD'{"}"}],{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; total_price: 20,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; invoice_num: 'invoice_num'{"\n"}&nbsp; &nbsp; {"}"}{"\n"});</pre>
                </code>
              </div>
            </div>
            <br />
            <div className="text-left">
              {/* HTML Box */}
              <div className="codeheader" id="codeheader_html">Payment Success: Track the following standard event when a visitor views your payment success page:</div>
              <div id="codebox" style={{backgroundColor: 'white'}}>
                <code data-language="html" wrap="hard" style={{textAlign: 'left', width: '100%', height: '100%', overflowX: 'hidden', backgroundColor: 'white', color: 'black', wordWrap: 'break-word'}}>
                  <pre>opix('event', 'payment_success',{"\n"}&nbsp; &nbsp; {"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; products: [{"{"}{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_id: 'product_id',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; product_name: 'product_name',{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; category_name: 'category', // or set to empty string{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; quality: 2,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; unit_price: 10,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; currency: 'USD'{"}"}],{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; total_price: 20,{"\n"}&nbsp; &nbsp; &nbsp; &nbsp; invoice_num: 'invoice_num'{"\n"}&nbsp; &nbsp; {"}"}{"\n"});</pre>
                </code>
              </div>
            </div>
          </div>
        </section>


            </div>

            {/* Footer*/}
                <footer className="footer py-8">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 text-lg-left">Copyright © Bilröst 2021</div>
                        <div className="col-lg-6 text-lg-right">
                        <a href="/private_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myPp">Privacy Policy</a>
                        <a href="/cookies_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Cookies Policy</a>
                        <a href="https://www.lab.bilrost.io" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Service</a>
                        <a href="https://www.lab.bilrost.io/trends" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Trends</a>
                        </div>
                    </div>
                    </div>
                    {/* End of Term of use Prompt */}
                </footer>
            </div>

            
            
        );
    }
}


export default Unsubscript;