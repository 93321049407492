import React, { Component } from 'react';
import './App.css';
import {
  Route,
  withRouter,
  Switch
} from 'react-router-dom';

import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';

import PrivatePolicy from '../home/PrivatePolicy';
import CookiesPolicy from '../home/CookiesPolicy';

import PollList from '../poll/PollList';
import { Campaign } from '../campaign/Campaign';
import { Audience } from '../audience/Audience';
import { Profile } from '../profile/Profile';
import { Subscription } from '../subscription/Subscription';
import { Upgrade } from '../upgrade/Upgrade';
import MasterForm from "../campaign/MasterForm";
import NewPoll from '../poll/NewPoll';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import ChangePassword from '../user/change_password/ChangePassword';
import ForgetPassword from '../user/forget_password/ForgetPassword';
import Profile2 from '../user/profile/Profile';
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';

import bilrost_digital_y from '../home/img/bilrost_digital_y.png';

import { Layout, notification } from 'antd';
const { Content } = Layout;

class ControlPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: true
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });    
  }

  loadCurrentUser() {
    getCurrentUser()
    .then(response => {
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false
      });
    }).catch(error => {
      this.setState({
        isLoading: false
      });  
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  handleLogout(redirectTo="/", notificationType="success", description="You're successfully logged out.") {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false
    });

    this.props.history.push(redirectTo);
    
    notification[notificationType]({
      message: 'Notification',
      description: description,
    });
  }

  handleLogin() {
    notification.success({
      message: 'Notification',
      description: "You're successfully logged in.",
    });
    this.loadCurrentUser();
    this.props.history.push("/");
  }

  render() {
    if(this.state.isLoading) {
      return <LoadingIndicator />
    }
    
    return (
      <Layout className="app-container">

          
      <Content >
      <div style={{display: 'flex'}}>

      <div className="sidebar sidebar-dark min-vh-100 " id="sidebar" style={{flex: '1'}}>
        <div className="sidebar-brand d-none d-md-flex">
          <img src={bilrost_digital_y} width={210} height={35} alt=""/>
        </div>
        <ul className="sidebar-nav" data-coreui="navigation" data-simplebar>
          <li className="nav-title">Members</li>
          <li className="nav-item"><a className="nav-link" href="/control_panel/profile">
            <svg className="nav-icon">
            <use xlinkHref="./svg/free.svg#cil-drop" />
            </svg> Profile</a>
          </li>
          <li className="nav-item"><a className="nav-link" href="/control_panel/subscription">
            <svg className="nav-icon">
            <use xlinkHref="./svg/free.svg#cil-pencil" />
            </svg> Subscription</a>
          </li>
          <li className="nav-item"><a className="nav-link" href="/control_panel/audience">
            <svg className="nav-icon">
            <use xlinkHref="./svg/free.svg#cil-pencil" />
            </svg> Audience</a>
          </li>

          <li className="nav-item"><a className="nav-link" href="/developer" target="_blank">
            <svg className="nav-icon">
            <use xlinkHref="./svg/free.svg#cil-pencil" />
            </svg> Developer</a>
          </li>

          <li className="nav-item"><a className="nav-link" href="/control_panel/upgrade">
            <svg className="nav-icon">
            <use xlinkHref="./svg/free.svg#cil-pencil" />
            </svg> Upgrade</a>
          </li>

          <li className="nav-title">Message Tools</li>
          <li className="nav-item"><a className="nav-link" href="/control_panel/campaign">
            <svg className="nav-icon">
            <use xlinkHref="node_modules/@coreui/icons/sprites/free.svg#cil-chart-pie" />
            </svg> Campaign</a>
          </li>
        </ul>
      </div>

      <div class="wrapper d-flex flex-column min-vh-100 bg-light bg-opacity-50 dark:bg-transparent" style={{flex: '6'}}>
				{/*<header class="header header-sticky mb-4">*/}
          <AppHeader isAuthenticated={this.state.isAuthenticated} 
            currentUser={this.state.currentUser} 
            onLogout={this.handleLogout} />
				{/*</header>*/}
				
				<div class="body flex-grow-1 px-3">
          <div >
            <Switch>      
              <Route exact path="/control_panel/poll" 
                render={(props) => <PollList isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route exact path="/control_panel/campaign_detail" 
                render={(props) => <MasterForm isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route exact path="/control_panel/campaign" 
                render={(props) => <Campaign isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route exact path="/control_panel/audience" 
                render={(props) => <Audience isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route exact path="/control_panel/subscription" 
                render={(props) => <Subscription isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route exact path="/control_panel/profile" 
                render={(props) => <Profile isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route exact path="/control_panel/upgrade" 
                render={(props) => <Upgrade isAuthenticated={this.state.isAuthenticated} 
                    currentUser={this.state.currentUser} handleLogout={this.handleLogout} {...props} />}>
              </Route>
              <Route path="/control_panel/users/:username" 
                render={(props) => <Profile2 isAuthenticated={this.state.isAuthenticated} currentUser={this.state.currentUser} {...props}  />}>
              </Route>
              {/*<PrivateRoute authenticated={this.state.isAuthenticated} path="/control_panel/poll/new" component={NewPoll} handleLogout={this.handleLogout}></PrivateRoute>*/}
              <Route path="/control_panel/change_password" component={ChangePassword}></Route>


              <Route component={NotFound}></Route>
              
            </Switch>
          </div>
          </div>
          <footer class="footer" style={{justifyContent: 'center'}}>
          <div>Copyright © Bilröst 2021</div>
          </footer>
      
			</div>
      </div>

      </Content>
    </Layout>
    );
  }
}

export default withRouter(ControlPanel);
