import React, { useRef, useEffect, useState } from "react";


const Step5 = props => {
  if (props.currentStep !== 5) {
    return null;
  }

  return (
    <>
      Step5
      <div dangerouslySetInnerHTML={ {__html: props.campaign.emailTemplateHtml.value} }></div>
    </>
  );
};

export default Step5;
