import React, { Component } from 'react';
import { login, registrationConfirm } from '../../util/APIUtils';
import './Login.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../../constants';

import { Form, Input, Button, Icon, notification } from 'antd';
import bilrost_digital_y from './img/bilrost_digital_y.png';
import background from "./img/header-bg.jpg";

const FormItem = Form.Item;

class Login extends Component {
    render() {
        const AntWrappedLoginForm = Form.create()(LoginForm)
        return (
            <div>
            <div style={{ backgroundImage: `url(${background})` }}>
                <nav class="navbar navbar-expand-lg navbar-dark navbar-shrink" id="mainNav">
                    <div class="container">
                        <a class="navbar-brand js-scroll-trigger" href="/"><img src={bilrost_digital_y} alt="centered image"/></a>
                    </div>
                </nav>
                <div className="login-container" style={{ maxWidth: '600px' }}>
                    <div class="text-left">
                        <h2 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white'}}>SIGN IN</h2>
                        <h5 class="section-subheading text-muted">Don't have an account? <a href="/signup" style={{color:'#fed136'}}>sign up</a></h5>
                    </div>
                    <div className="login-content">
                        <AntWrappedLoginForm onLogin={this.props.onLogin} />
                        <br/>
                    </div>
                </div>
            </div>

            {/* Footer*/}
                <footer className="footer py-8">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 text-lg-left">Copyright © Bilröst 2021</div>
                        <div className="col-lg-6 text-lg-right">
                        <a href="/private_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myPp">Privacy Policy</a>
                        <a href="/cookies_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Cookies Policy</a>
                        <a href="https://www.lab.bilrost.io" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Service</a>
                        <a href="https://www.lab.bilrost.io/trends" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Trends</a>
                        </div>
                    </div>
                    </div>
                    {/* End of Term of use Prompt */}
                </footer>
            </div>
        );
    }
}

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (token){
            console.log(token);
            registrationConfirm(token)
            .then(response => {
                notification.success({
                    message: 'Notification',
                    description: response.message,
                });          
            }).catch(error => {
                notification.error({
                    message: 'Notification',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            });

        }
    }
    
    handleSubmit(event) {
        event.preventDefault();   
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const loginRequest = Object.assign({}, values);
                login(loginRequest)
                .then(response => {
                    localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                    this.props.onLogin();
                }).catch(error => {
                    if(error.status === 401) {
                        notification.error({
                            message: 'Notification',
                            description: 'Your Username or Password is incorrect. Please try again!'
                        });                    
                    } else {
                        notification.error({
                            message: 'Notification',
                            description: error.message || 'Sorry! Something went wrong. Please try again!'
                        });                                            
                    }
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">
                <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>USERNAME / EMAIL</h5>
                <FormItem>
                    {getFieldDecorator('usernameOrEmail', {
                        rules: [{ required: true, message: 'Please input your username or email!' }],
                    })(
                    <Input 
                        prefix={<Icon type="user" />}
                        size="large"
                        name="usernameOrEmail" 
                        placeholder="Username or Email" />    
                    )}
                </FormItem>
                <h5 class="section-heading text-uppercase" style={{ display: 'block',  color: 'white' }}>PASSWORD</h5>
                <FormItem>
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                    <Input 
                        prefix={<Icon type="lock" />}
                        size="large"
                        name="password" 
                        type="password" 
                        placeholder="Password"  />                        
                )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" size="large" className="login-form-button text-uppercase" style={{ backgroundColor: '#fec810',  borderColor: '#fec503' }}>Login</Button>
                    <Link to="/forget_password" style={{color:'#fed136'}}>forget password?</Link>
                </FormItem>
            </Form>
            
            
        );
    }
}


export default Login;