import React, { useRef, useEffect, useState } from "react";
import { getCampaignDetail, getSubscription } from '../util/APIUtils';
import { notification } from 'antd';
import Geocode from "react-geocode";

const Step6 = props => {
  if (props.currentStep !== 6) {
    return null;
  }

  const [campaignInfo, setCampaignInfo] = useState({
    campaignName: '',
    emailSubject: '',
    senderName: '',
    senderEmail: '',
    eventType: '',
    campaignType: '',
    channel: '',
    conversionFocus: '',
    promotionPeriod: '',
    commissionPercentage: '',
    chargeOfClickThrough: '',
    targetCountry: '',
    targetGender: '',
    targetArea: '',
    targetRadius: '',
    targetLat: '',
    targetLng: '',
    numberOfLeads: '',
    emailStatus: '',
    emailTemplateJson: '',
    emailTemplateHtml: '',
    startDate: '',
    endDate: ''
  });

  const [userQuota, setUserQuota] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    if (localStorage.getItem("campaignId")){
      getCampaignDetail(localStorage.getItem("campaignId"))
      .then(response => {
        console.log(response);
        if (response.success){
          setCampaignInfo(response);
  
          Geocode.setApiKey("AIzaSyBm523bXHDV1mJet5Peh8KgU6HJmLwz9AU");
          Geocode.setLanguage("en");
          Geocode.fromLatLng(response.targetLat, response.targetLng).then(
            response => {
              const address = response.results[0].formatted_address;
              setAddress(address);
            },
            error => {
              console.error(error);
            }
          );

        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
    }

    getSubscription()
      .then(response => {
        console.log(response);
        if (response.success){
            setUserQuota(response.userQuota);
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });



  }, []);

  return (
    <>
      <h3>Summary</h3>
      <table class="table table-striped">
        <thead>
      <tr>
        <th scope="col">Segmentation</th>
        <th scope="col"></th>
        <th scope="col">Audience</th>
        <th scope="col"></th>
        <th scope="col">Schedule</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
    <tr>
        <td scope="row">Campaign</td>
        <td>{campaignInfo.campaignName}</td>
        <td scope="row">Country</td>
        <td>{campaignInfo.targetCountry}</td>
        <td scope="row">Event Start Date</td>
        <td>{campaignInfo.startDate}</td>
      </tr>
      
      <tr>
        <td scope="row">Channel</td>
        <td>{campaignInfo.channel}</td>
        <td scope="row">Gender</td>
        <td>{campaignInfo.targetGender}</td>
        <td scope="row">Event End Date</td>
        <td>{campaignInfo.endDate}</td>
      </tr>
      
      <tr>
        <td scope="row">Promotion Period</td>
        <td>{campaignInfo.promotionPeriod} days</td>
        <td scope="row">Target Area</td>
        <td>{address}</td>
        <td scope="row"></td>
        <td></td>
      </tr>
      
      <tr>
        {('purchase' === props.campaign.campaignType.value) && <>
          <td scope="row">Commission</td>
          <td>{campaignInfo.commissionPercentage}%</td>
        </>}
        {('lead' === props.campaign.campaignType.value) && <>
          <td scope="row">Charge of ClickThrough</td>
          <td>{campaignInfo.chargeOfClickThrough} Clicks</td>
        </>}
        <td scope="row">Target Radius</td>
        <td>{campaignInfo.targetRadius} km</td>
        <td scope="row"></td>
        <td></td>
      </tr>
      
      <tr>
        <td scope="row"></td>
        <td></td>
        <td scope="row">Number of Leads Send</td>
        <td>{campaignInfo.numberOfLeads}</td>
        <td scope="row"></td>
        <td></td>
      </tr>
      
      <tr>
        <td scope="row"></td>
        <td></td>
        <td scope="row">You currently have</td>
        <td>{userQuota} Leads</td>
        <td scope="row"></td>
        <td></td>
      </tr>
      
      <tr>
        <td scope="row"></td>
        <td></td>
        <td scope="row">Extra Leads</td>
        <td>{ ((campaignInfo.numberOfLeads) - (userQuota) < 0) ? 0 : (campaignInfo.numberOfLeads) - (userQuota)}</td>
        <td scope="row"></td>
        <td></td>
      </tr>
      
      
    </tbody>
      </table>
    </>
  );
};

export default Step6;
