import React, { Component } from 'react';

import { contactUs } from '../util/APIUtils';
import { Form, Input, Button, notification } from 'antd';

import {purecookieDismiss} from './js/purecookie.js'; 
import bilrost_digital_y from './img/bilrost_digital_y.png';
import people from './img/about/people.svg';
import emoji_heart_eyes from './img/about/emoji-heart-eyes.svg';
import geo_alt from './img/about/geo-alt.svg';
import M from './img/about/M.svg';
import H from './img/about/H.svg';
import D from './img/about/D.svg';
import email_marketing_th from './img/portfolio/email_marketing_th.jpg';
import affiliate_marketing_th from './img/portfolio/affiliate_marketing_th.jpg';
import sms_marketing_th from './img/portfolio/sms_marketing_th.jpg';
import social_media_marketing_th from './img/portfolio/social_media_marketing_th.jpg';
import user_behavior_th from './img/portfolio/user_behavior_th.jpg';
import sales_lead_generation_th from './img/portfolio/sales_lead_generation_th.jpg';
import email_marketing from './img/portfolio/email_marketing.jpg'; 
import affiliate_marketing from './img/portfolio/affiliate_marketing.jpg';
import sms_marketing from './img/portfolio/sms_marketing.jpg';
import social_media_marketing from './img/portfolio/social_media_marketing.jpg'; 
import user_behavior from './img/portfolio/user_behavior.jpg'; 
import sales_lead_generation from './img/portfolio/sales_lead_generation.jpg'; 
import close_icon from './img/close-icon.svg'; 

import { $ }  from 'react-jquery-plugin';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core';

import './css/Home.css';
import './css/styles.css';
import './css/pricing.css';
import './css/purecookie.css';
import 'font-awesome/css/font-awesome.min.css';
import TextArea from 'antd/lib/input/TextArea';

const FormItem = Form.Item;


class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submit: {
                value: 'unsubmitted'
            },
            name: {
                value: ''
            },
            email: {
                value: ''
            },
            phone: {
                value: ''
            },
            message: {
                value: ''
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);

    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    validateName = (name) => {
        if(!name) {
            return {
                validateStatus: 'error',
                errorMsg: 'Name may not be empty'                
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email may not be empty'                
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    validatePhone = (phone) => {
        if(!phone) {
            return {
                validateStatus: 'error',
                errorMsg: 'Phone may not be empty'                
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    validateMessage = (message) => {
        
        this.setState({
            submit: {
                status: 'unsubmitted',
            }
        });

        if(!message) {
            return {
                validateStatus: 'error',
                errorMsg: 'Message may not be empty'                
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
              };            
        }
    }

    

    handleSubmit(event) {
        event.preventDefault();
    
        const contactUsRequest = {
            name: this.state.name.value,
            email: this.state.email.value,
            phone: this.state.phone.value,
            message: this.state.message.value
        };
        this.setState({
            submit: {
                status: 'submitted',
            }
        });
        contactUs(contactUsRequest)
        .then(response => {
            notification.success({
                message: 'Notification',
                description: response.message,
            });          
        }).catch(error => {
            notification.error({
                message: 'Notification',
                description: error.message
            });
        });
    }

    
    isFormInvalid() {
        return !(this.state.name.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success' &&
            this.state.phone.validateStatus === 'success' &&
            this.state.message.validateStatus === 'success' &&
            this.state.submit.status === 'unsubmitted'
        );
    }

    componentDidMount () {
        this.purecookieDismiss = this.purecookieDismiss.bind(this);

        $(window).scroll(() => {
            // put your code here
        });
        this.jQueryCode();

       

    }
    jQueryCode = () => {
        "use strict"; // Start of use strict

        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
            if (
                window.location.pathname.replace(/^\//, "") ==
                    this.pathname.replace(/^\//, "") &&
                    window.location.hostname == this.hostname
            ) {
                var target = $(this.hash);
                target = target.length
                    ? target
                    : $("[name=" + this.hash.slice(1) + "]");
                if (target.length) {
                    $("html, body").animate(
                        {
                            scrollTop: target.offset().top - 72,
                        },
                        1000,
                        "easeInOutExpo"
                    );
                    return false;
                }
            }
        });
    
        // Closes responsive menu when a scroll trigger link is clicked
        $(".js-scroll-trigger").click(function () {
            $(".navbar-collapse").collapse("hide");
        });
    
        // Activate scrollspy to add active class to navbar items on scroll
        $("body").scrollspy({
            target: "#mainNav",
            offset: 74,
        });

        // Collapse Navbar
        var navbarCollapse = function () {
            try{
                if ($("#mainNav").offset().top > 100) {
                    $("#mainNav").addClass("navbar-shrink");
                } else {
                    $("#mainNav").removeClass("navbar-shrink");
                }
            } catch(e){

            }
            
        };
        // Collapse now if page is not at top
        navbarCollapse();
        // Collapse the navbar when page is scrolled
        $(window).scroll(navbarCollapse);
    }


    purecookieDismiss (){
        purecookieDismiss();
    }
    
    render() {
        return (
            <div>
                {/*<meta name="robots" content="noindex" />
                <meta name="googlebot" content="noindex" />
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" />
                <meta name="description" content="Bilröst is the digital marketing platform of affiliate marketing, lead generation and user acquisition for small businesses to grow business by email, SMS, MMS, whatsapp and more!" />
                <title>Bilröst an Email Marketing | SMS MMS Marketing | Lead Generation | User Acquisition Digital Marketing Platform</title>
                <link rel="icon" type="image/x-icon" th:href="@{|${cdnResource}resources/assets/img/bilrost_y3.ico|}" />
                <link rel="alternate" href="https://bilrost.io/" hrefLang="x-default" /><link rel="alternate" href="https://bilrost.io/" hrefLang="en" />
                <meta className="swiftype" name="title" data-type="string" content="Bilröst an Email Marketing | SMS MMS Marketing | Lead Generation | User Acquisition Digital Marketing Platform" />
                <meta className="swiftype" name="description" data-type="string" content="Bilröst is the digital marketing platform of affiliate marketing, lead generation and user acquisition for small businesses to grow business by email, SMS, MMS, whatsapp and more!! " />
                <meta content index,follow,archive"" name robots"" />
                <meta content en"" name language"" />
                <meta property="og:title" content="We help small business acquisition, drive sales more, faster and simpler | Bilröst" />
                <meta property="og:locale" content="en-GB" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Bilröst" />
                <meta property="og:url" content="https://bilrost.io/" />
                <meta property="og:description" content="Bilröst helps small business acquisition, drive sales more, faster and simpler. All in one digital marketing platform." />
                <meta property="og:image" content="resources/assets/img/bilrost_y3.ico" />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:site" content="@bilrostdigital" />
                <meta property="twitter:title" content="We help small business acquisition, drive sales more, faster and simpler | Bilröst" />
                <meta property="twitter:description" content="Bilröst helps small business acquisition, drive sales more, faster and simpler. All in one digital marketing platform." />
        <meta property="twitter:image" content="resources/assets/img/bilrost_y3.ico" />*/}
               
                {/* Navigation*/}
                <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
                    <div className="container">
                    <a className="navbar-brand js-scroll-trigger" href="/"><img src={bilrost_digital_y} alt=""/></a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i className="fas fa-bars ml-1" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav text-uppercase ml-auto">
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="./#feature">Feature</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="./#portfolio">Product</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="./#about">About</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="./#pricing">Pricing</a></li>
                        {/* <li class="nav-item"><a class="nav-link js-scroll-trigger" href="./#donate_us">Donate us</a></li> */}
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="./#contact">Contact us</a></li>
                        {/*<li className="nav-item"><a className="nav-link js-scroll-trigger" href="./signin">Developer</a></li>*/}
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="https://www.lab.bilrost.io/blog" target="_blank">BLOG</a></li>
                        {/* <li class="nav-item"><a class="nav-link js-scroll-trigger" href="./signin">Sign in</a></li>*/}
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="./signup">Sign up free</a></li>
                        </ul>
                    </div>
                    </div>
                </nav>
                {/* Masthead*/}
                <header className="masthead">
                    <section className="page-section">
                    <div className="container">
                        {/* "masthead-heading */}
                        <div className="masthead-heading">We're Bilröst</div>
                        <div className="masthead-subheading text-uppercase">Acquisition and Drive Sales More. Faster. Simpler.</div>
                        <br />
                        {/* <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="/signup">Get Started Free</a> */}
                        <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#feature"   style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Find out more</a>
                        </div>
                    </section>
                </header>
                {/* Feature*/}
                <section className="page-section" id="feature">
                    <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Feature</h2>
                        <h3 className="section-subheading text-muted">The reason you are with us.</h3>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                        <svg className="svg-inline--fa fa-circle fa-w-16 fa-stack-2x text-primary" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path style={{color:'#FEC503', borderColor:'#FEC503'}} fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg>
                        <img width={96} src={people} style={{"position":"absolute","top":"50%","left":"50%","height":"70%","width":"70%","margin":"-35% 0 0 -35%"}} alt=""/>
                        </span>
                        <h4 className="my-3">Segmentation</h4>
                        <p className="text-muted">Market segmentation is the process of dividing a target market into smaller, more defined categories. It segments customers and audiences into groups that share similar characteristics such as demographics, interests, needs, or location.</p>
                        <p className="text-muted">With Bilröst, you are able to target customers by more defined categories to achieve the highest conversion with multiple marketing channels such as mobile marketing, direct email marketing and more.</p>
                        </div>
                        <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                        <svg className="svg-inline--fa fa-circle fa-w-16 fa-stack-2x text-primary" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path style={{color:'#FEC503', borderColor:'#FEC503'}} fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg>
                            <img width={96} src={emoji_heart_eyes} style={{"position":"absolute","top":"50%","left":"50%","height":"70%","width":"70%","margin":"-35% 0 0 -35%"}} alt=""/>
                        </span>
                        <h4 className="my-3">Behavior Analysis</h4>
                        <p className="text-muted">Behavior analysis is a natural science that seeks to understand the behavior of individuals. That is behavior analysis, study how people purchase behavior, market trends, and experiential factors influence the behavior of humans.</p>
                        <p className="text-muted">With our innovational technology of behavior analysis customers' footprint on mobile devices, social media, and the internet to provide the most accurate targeting to marketing campaigns. </p>
                        </div>
                        <div className="col-md-4">
                        <span className="fa-stack fa-4x">
                        <svg className="svg-inline--fa fa-circle fa-w-16 fa-stack-2x text-primary" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path style={{color:'#FEC503', borderColor:'#FEC503'}} fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path></svg>
                            <img width={96} src={geo_alt} style={{"position":"absolute","top":"60%","left":"50%","height":"70%","width":"70%","margin":"-35% 0 0 -35%"}} alt=""/>
                        </span>
                        <h4 className="my-3">GEO-Targeting</h4>
                        <p className="text-muted">In GEO-marketing and GEO-targeting is the method of delivering different content to visitors based on their GEO-location. This includes country, region, state, city, zip code, organization, IP address, or other criteria.</p>
                        <p className="text-muted">The technology allows our partners to target consumers not only online but more accurately on their daily activities and live time purchase footprint. Examples such as restaurant marketing campaigns, pop-up store campaigns, and store events.</p>
                        </div>
                    </div>
                    </div>
                </section>
                {/* Portfolio Grid*/}
                <section className="page-section bg-light" id="portfolio">
                    <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Product</h2>
                        <h3 className="section-subheading text-muted">Your omni channels marketing platform.</h3>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal1">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"></div>
                            </div>
                            <img className="img-fluid" src={email_marketing_th} alt=""/>
                            </a>
                            <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">EMAIL</div>
                            <div className="portfolio-caption-subheading text-muted">Direct Marketing</div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal2">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"></div>
                            </div>
                            <img className="img-fluid" src={affiliate_marketing_th} alt=""/>
                            </a>
                            <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">AFFILIATE</div>
                            <div className="portfolio-caption-subheading text-muted">Network Marketing Platform</div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal3">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"></div>
                            </div>
                            <img className="img-fluid" src={sms_marketing_th} alt=""/>
                            </a>
                            <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">SMS &amp; MMS</div>
                            <div className="portfolio-caption-subheading text-muted">Mobile Marketing</div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal4">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"></div>
                            </div>
                            <img className="img-fluid" src={social_media_marketing_th} alt=""/>
                            </a>
                            <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">RICH MESSAGE</div>
                            <div className="portfolio-caption-subheading text-muted">Social Media Marketing</div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal5">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"></div>
                            </div>
                            <img className="img-fluid" src={user_behavior_th} alt=""/>
                            </a>
                            <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">BEHAVIOR</div>
                            <div className="portfolio-caption-subheading text-muted">User Analysis</div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                        <div className="portfolio-item">
                            <a className="portfolio-link" data-toggle="modal" href="#portfolioModal6">
                            <div className="portfolio-hover">
                                <div className="portfolio-hover-content"></div>
                            </div>
                            <img className="img-fluid" src={sales_lead_generation_th} alt=""/>
                            </a>
                            <div className="portfolio-caption">
                            <div className="portfolio-caption-heading">SALE LEADS</div>
                            <div className="portfolio-caption-subheading text-muted">Leads Generator</div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                {/* About*/}
                <section className="page-section" id="about">
                    <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase" style={{"color":"white"}}>About Us</h2>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-3" />
                        <div className="col-md-6">
                        <p style={{"color":"white"}}>Our humble opened our eyes to the needs of growing businesses: marketers need tools that can efficiently scale as they mature.</p>
                        <p style={{"color":"white"}}>That’s why we created Bilröst. Serving small and medium-sized businesses to grow like a rocket is our mission. We help small businesses do big scale digital direct marketing, with the right tools and guidance every step of the way. We do not sell solutions. We produce business outcomes.</p>
                        <p style={{"color":"white"}}>Our reputation is built on technical expertise, industry experience, quality support, and service reliability. From bulk marketing to mission-critical solutions, if you need a business messaging provider, you’ve come to the right place.</p>
                        </div>
                        <div className="col-md-3" />
                    </div>
                    </div>
                </section>
                {/* pricing*/}
                <section className="page-section bg-light" id="pricing">
                    <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Pricing</h2>
                    </div>
                    <div id="price">
                        <div className="row text-center">
                        {/*price tab*/}
                        {/* <div class="col-md-3"><div class="plan"><div class="plan-inner"><div class="entry-title"><h3>Beginner</h3>
                                Starting from
                                <div class="price">$99<span> / month</span></div></div><div class="entry-content"><ul><li><strong>1x</strong> User</li><li><strong>$0.099</strong> / each lead</li><li><strong><s>$1188</s> $1088</strong> / year</li><li><strong>1000 Leads</strong> / month (Guaranteed)</li><li><strong></strong> Email, WhatsAPP, SMS, FB Messenger</li><li><strong></strong><s>Re-targeting, Automation</s></li><li><strong>15-30%</strong> Commission Each Transaction</li><li><strong></strong> Dashboard</li></ul></div><div class="btn"><a href="#">Subscription</a></div><div class="entry-content"><br/></div></div></div></div>*/}
                        {/* end of price tab*/}
                        {/*price tab*/}
                        <div className="col-md-12">
                            <div className="plan basic">
                            <div className="plan-inner">
                                <div className="hot">RECOMMEND</div>
                                <div className="entry-title">
                                <h3 style={{"color": "#FFF"}} >Standard</h3>
                                <h6 style={{"color": "#FFF"}} >Starting from</h6>
                                <div className="price">£189 <span>/month</span>
                                </div>
                                </div>
                                <div className="entry-content">
                                <ul>
                                    <li>
                                    {/*<s>£2268</s>*/}
                                    Starting from <strong>£189</strong> / month
                                    </li>
                                    <li>
                                    <strong>1x</strong> User
                                    </li>
                                    <li>
                                    <strong>£0.0095</strong> / each
                                    </li>
                                    <li>
                                    <strong>20000 Leads</strong> / month
                                    </li>
                                    <li>Email channel</li>
                                    <li>Unlimit daily email</li>
                                    <li>Site and event tracking</li>
                                    <li>Drag and drop email builder</li>
                                    <li>Ticket and email support</li>
                                </ul>
                                </div>
                                <div className="btn">
                                <a href="/signup">SUBSCRIPTION</a>
                                </div>
                                <div className="entry-content">
                                <br />
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* end of price tab*/}
                        {/*price tab*/}
                        {/* <div class="col-md-3"><div class="plan standard"><div class="plan-inner"><div class="entry-title"><h3>Premium</h3>
                                Starting from
                                <div class="price">$2950<span> / month</span></div></div><div class="entry-content"><ul><li><strong>1x</strong> User</li><li><strong>$0.059</strong> / each lead</li><li><strong><s>$35400</s> $33600</strong> / year</li><li><strong>50000 Leads</strong> / month (Guaranteed)</li><li><strong></strong> Email, WhatsAPP, SMS, FB Messenger</li><li><strong></strong> Re-targeting, Automation</li><li><strong>15-25%</strong> Commission Each Transaction</li><li><strong></strong> Dashboard</li></ul></div><div class="btn"><a href="#">Subscription</a></div><div class="entry-content"><br/></div></div></div></div>*/}
                        {/* end of price tab*/}
                        {/*price tab*/}
                        {/*<div class="col-md-3"><div class="plan ultimite"><div class="plan-inner"><div class="entry-title"><h3>Enterprise</h3>
                                Starting from
                                <div class="price">$4900<span> / month</span></div></div><div class="entry-content"><ul><li><strong>1x</strong> User</li><li><strong>$0.049</strong> / each lead</li><li><strong><s>$58800</s> $55000</strong> / year</li><li><strong>100000 Leads</strong> / month (Guaranteed)</li><li><strong></strong> Email, WhatsAPP, SMS, FB Messenger</li><li><strong></strong> Re-targeting, Automation</li><li><strong>15-20%</strong> Commission Each Transaction</li><li><strong></strong> Dashboard</li></ul></div><div class="btn"><a href="#">Subscription</a></div><div class="entry-content"><br/></div></div></div></div>*/}
                        {/* end of price tab*/}
                        </div>
                    </div>
                    </div>
                </section>
                {/* Donate Us*/}
                {/* <section class="page-section" id="donate_us">
                    <div class="container">
                        <div class="text-center">
                            <h2 class="section-heading text-uppercase">Donate US</h2>
                        </div>

                        <ul class="timeline">
                            <li>
                                <div class="timeline-image">
                                <img width="108" src={about/M|}" style="position: absolute; top: 50%; left: 50%; height: 70%; width: 70%; margin: -35% 0 0 -35%;">
                                
                                </div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>OUR MISSION</h4>
                                    </div>
                                    <div class="timeline-body"><p class="text-muted">We help small businesses do big scale digital direct marketing, with the right tools and guidance every step of the way. We do not sell solutions. We produce business outcomes.</p></div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image"><img width="108" src={about/H|}" style="position: absolute; top: 50%; left: 50%; height: 60%; width: 60%; margin: -30% 0 0 -30%;"></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>HOWEVER</h4>
                                    </div>
                                    <div class="timeline-body"><p class="text-muted">To implement the technical development we are facing an unexpected development cost to fulfill our mission. We sincerely require support from donation or investment to allow us continue the technical development of our platform.</p></div>
                                </div>
                            </li>
                            <li>
                                <div class="timeline-image"><img width="108" src={about/D|}" style="position: absolute; top: 50%; left: 50%; height: 70%; width: 70%; margin: -35% 0 0 -35%;"></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>DONATE US</h4>
                                    </div>
                                    <div class="timeline-body"><p class="text-muted">Grateful to remarkable individuals and institutions like you to ensure that we have the necessary resources to continue our mission. We manage funds and resources responsibly to recruit and support skilled, passionate staff who advance our communities and values, also includes ongoing engineering improvements, product development, design and research, and legal support.</p></div>
                                </div>
                            </li>
                            <li class="timeline-inverted">
                                <div class="timeline-image"><img width="108" src={about/P|}" style="position: absolute; top: 50%; left: 50%; height: 70%; width: 70%; margin: -35% 0 0 -35%;"></div>
                                <div class="timeline-panel">
                                    <div class="timeline-heading">
                                        <h4>OUR PROMISE</h4>
                                    </div>
                                    <div class="timeline-body"><p class="text-muted">Every donations with more than 99USD, we will be double rebate to you in your subscription with annual plan. Please click below to donate us.</p></div>
                                </div>
                            </li>
                            
                                    <li class="timeline-inverted">
                                <div class="timeline-image">
                                <a href="https://paypal.me/bilrostdigital" target="_blank">
                                    <h4 style="color: #fff;">
                                        Donate
                                        <br />
                                        Us
                                        <br />
                                        Now!
                                    </h4>
                                </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section> */}
                {/* Contact*/}
                <section className="page-section" id="contact">
                    <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Contact Us</h2>
                        <h3 className="section-subheading text-muted">Feature and product enquiry. Investor message also welcome!</h3>
                    </div>
                    <Form onSubmit={this.handleSubmit}  name="sentMessage" noValidate="novalidate">
                        <div className="row align-items-stretch mb-5">
                        <div className="col-md-6">
                        <FormItem 
                            validateStatus={this.state.name.validateStatus}
                            help={this.state.name.errorMsg}>
                            <Input 
                                size="large"
                                name="name" 
                                autoComplete="off"
                                placeholder="Your name"
                                value={this.state.name.value} 
                                onChange={(event) => this.handleInputChange(event, this.validateName)} />    
                        </FormItem>
                         
                        <FormItem 
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input 
                                size="large"
                                name="email" 
                                type="email" 
                                autoComplete="off"
                                placeholder="Your email"
                                value={this.state.email.value} 
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />    
                        </FormItem>

                        <FormItem 
                            validateStatus={this.state.phone.validateStatus}
                            help={this.state.phone.errorMsg}>
                            <Input 
                                size="large"
                                name="phone" 
                                autoComplete="off"
                                placeholder="Your phone"
                                value={this.state.phone.value} 
                                onChange={(event) => this.handleInputChange(event, this.validatePhone)} />    
                        </FormItem>
                        </div>
                        <div className="col-md-6">
                            <FormItem
                                validateStatus={this.state.message.validateStatus}
                                help={this.state.message.errorMsg}>
                                <TextArea 
                                    size="large"
                                    name="message" 
                                    autoComplete="off"
                                    placeholder="Your message"
                                    value={this.state.message.value} 
                                    onChange={(event) => this.handleInputChange(event, this.validateMessage)} 
                                    style={{height: '171px'}}
                                    />
                            </FormItem>
                        </div>
                        </div>
                            <FormItem>
                                    <Button type="primary" 
                                        htmlType="submit" 
                                        size="large" 
                                        className="signup-form-button btn btn-primary text-uppercase"
                                        style={{ backgroundColor: '#fec810',  borderColor: '#fec503' }}
                                        disabled={this.isFormInvalid()}>Contact us now!</Button>
                            </FormItem>
                       
                    </Form>
                    </div>
                </section>
                {/* Footer*/}
                <footer className="footer py-8">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-3 text-lg-left">Copyright © Bilröst 2021</div>
                        <div className="col-lg-6 text-lg-right">
                        <a href="/private_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myPp">Privacy Policy</a>
                        <a href="/cookies_policy" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Cookies Policy</a>
                        <a href="https://www.lab.bilrost.io" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Service</a>
                        <a href="https://www.lab.bilrost.io/trends" target="_blank" className="mr-3" style={{"color": "#212529"}} id="myTou">Trends</a>
                        </div>
                    </div>
                    </div>
                    {/* End of Term of use Prompt */}
                </footer>
                {/* Portfolio Modals*/}
                {/* Modal 1*/}
                <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-dismiss="modal"><img src={close_icon} alt="Close modal" /></div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                            <div className="modal-body">
                                {/* Project Details Go Here*/}
                                <h2 className="text-uppercase">Email Marketing</h2>
                                <p className="item-intro text-muted">Marketing smarts for big ideas. Email Marketing Platform for Small Business.</p>
                                <img className="img-fluid d-block mx-auto" src={email_marketing} alt=""/>
                                <p>Email marketing is the act of sending a commercial message, typically to a group of people, using email. In its broadest sense, every email sent to a potential or current customer could be considered email marketing. It involves using email to send advertisements, request business, or solicit sales.</p>
                                <p>It has been around forever, and for good reason. It’s the most direct and effective way of connecting with your leads, nurturing them, and turning them into customers, consistently winning out over all other marketing channels.</p>
                                <p>With Bilröst, you are able to target customers by more defined categories to achieve the highest conversion with our email marketing solution.</p>
                                {/* <button class="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('pricing')">Pick a Plan</button>*/}
                                <button className="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('contact')" style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Modal 2*/}
                <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-dismiss="modal"><img src={close_icon} alt="Close modal" /></div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                            <div className="modal-body">
                                {/* Project Details Go Here*/}
                                <h2 className="text-uppercase">Affiliate Marketing Platform</h2>
                                <p className="item-intro text-muted">We help you make more money!</p>
                                <img className="img-fluid d-block mx-auto" src={affiliate_marketing} alt=""/>
                                <p>Bilröst is one of the best affiliate marketing platforms, with the innovation and latest technology our platform gives you the best solution to transform your network into valuable assert, with us our cloud solution transform your digital assets to revenue, sign up free now. Contact us for further information and integration requirements.</p>
                                <button className="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('contact')" style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Modal 3*/}
                <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-dismiss="modal"><img src={close_icon} alt="Close modal" /></div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                            <div className="modal-body">
                                {/* Project Details Go Here*/}
                                <h2 className="text-uppercase">Mobile Marketing</h2>
                                <p className="item-intro text-muted">Marketing smarts for big ideas. SMS and MMS Marketing Platform for Small Business.</p>
                                <img className="img-fluid d-block mx-auto" src={sms_marketing} alt=""/>
                                <p>SMS Marketing is sending promotional campaigns or transactional messages for marketing purposes using text messages (SMS). These messages are mostly meant to communicate time-sensitive offers, updates, and alerts to people who have consented to receive these messages from your business.</p>
                                <p>With Bilröst, you are able to target customers by more defined categories to achieve the highest conversion with our SMS marketing solution.</p>
                                {/* <button class="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('pricing')">Pick a Plan</button>*/}
                                <button className="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('contact')" style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Modal 4*/}
                <div className="portfolio-modal modal fade" id="portfolioModal4" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-dismiss="modal"><img src={close_icon} alt="Close modal" /></div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                            <div className="modal-body">
                                {/* Project Details Go Here*/}
                                <h2 className="text-uppercase">Social Media Marketing</h2>
                                <p className="item-intro text-muted">Facebook, WhatsAPP, Instagram and Social Media Integrated Marketing Platform for Small Business</p>
                                <img className="img-fluid d-block mx-auto" src={social_media_marketing} alt=""/>
                                <p>Rich messaging is a communication protocol between mobile-telephone carriers and between phone and carrier, aiming at replacing SMS messages with a text-message system that is richer, provides phonebook polling (for service discovery), and transmits in-call multimedia.</p>
                                <p>With Bilröst, you are able to target customers by more defined categories to achieve the highest conversion with multiple marketing channels well-known as WhatsApp and Facebook Messanger.</p>
                                {/* <button class="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('pricing')">Pick a Plan</button>*/}
                                <button className="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('contact')" style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Modal 5*/}
                <div className="portfolio-modal modal fade" id="portfolioModal5" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-dismiss="modal"><img src={close_icon} alt="Close modal" /></div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                            <div className="modal-body">
                                {/* Project Details Go Here*/}
                                <h2 className="text-uppercase">Customer Behavior Analysis</h2>
                                <p className="item-intro text-muted">The innovational technology help you fully understand customer behavior, user experience and customer journey.</p>
                                <img className="img-fluid d-block mx-auto" src={user_behavior} alt=""/>
                                <p>Dashboard builds market and customer intelligence programs that position companies to grow. We deliver fast, accurate insight and provides clear direction for clients in complex, changing markets. We combine extensive insight and tracking tools with the latest technology.</p>
                                {/* <button class="btn btn-primary" data-dismiss="modal" type="button" onclick="location.href='/signup'">Sign Up Free</button>*/}
                                <button className="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('contact')" style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Modal 6*/}
                <div className="portfolio-modal modal fade" id="portfolioModal6" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-dismiss="modal"><img src={close_icon} alt="Close modal" /></div>
                        <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                            <div className="modal-body">
                                {/* Project Details Go Here*/}
                                <h2 className="text-uppercase">Find Target Customers</h2>
                                <p className="item-intro text-muted">Segmentation and GEO-targeting help finding out your targeting customer groups.</p>
                                <img className="img-fluid d-block mx-auto" src={sales_lead_generation} alt=""/>
                                <p>Every business needs sales and sales are generated in a variety of ways. Leads can be created for purposes such as list building, e-newsletter list acquisition, or sales leads. The methods for generating leads typically fall under the umbrella of advertising. </p>
                                <p>With Bilröst, We help small businesses do big-scale digital direct marketing, with the right tools and guidance every step of the way. We help you with user acquisition and drive sales more, faster, and simpler.</p>
                                {/* <button class="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('pricing')">Pick a Plan</button>*/}
                                <button className="btn btn-primary" data-dismiss="modal" type="button" onclick="jump('contact')" style={{backgroundColor:'#FEC503', borderColor:'#FEC503'}}>Close</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* Bootstrap core JS*/}
                {/* Third party plugin JS*/}
                {/* Contact form JS*/}
                {/* Core theme JS*/}


                {/* Start of Cookie Prompt */}
                <div className="cookieConsentContainer" id="cookieConsentContainer">
                <div className="cookieTitle"></div>
                <div className="cookieDesc">
                    <p>By clicking “Accept All”, you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts. <a href="/cookies_policy" target="_blank">Learn more.</a>
                    </p>
                </div>
                <div className="cookieButton">
                    <a onClick={this.purecookieDismiss} style={{color:'#FFFFFF'}}>ACCEPT ALL</a>
                </div>
                </div>
                {/* End of Cookie Prompt */}


            </div>
        );
    }
}



export default Home;