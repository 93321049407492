import React, { Component } from 'react';
import { changePassword} from '../../util/APIUtils';
import { changePasswordSignin} from '../../util/APIUtils';
import './ChangePassword.css';
import { 
    PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH, ACCESS_TOKEN
} from '../../constants';

import { Form, Input, Button, notification } from 'antd';
const FormItem = Form.Item;

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: {
                value: ''
            },
            username: {
                value: ''
            },
            email: {
                value: ''
            },
            password: {
                value: ''
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');

        if (token){
            console.log(token);
            changePasswordSignin(token)
            .then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
            }).catch(error => {
                if(error.status === 401) {
                    notification.error({
                        message: 'Notification',
                        description: 'Your token is incorrect. Please try again!'
                    });                    
                } else {
                    notification.error({
                        message: 'Notification',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });                                            
                }
            });
        }
    }

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;        
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
    
        const signupRequest = {
            name: this.state.name.value,
            email: this.state.email.value,
            username: this.state.username.value,
            password: this.state.password.value
        };
        changePassword(signupRequest)
        .then(response => {
            notification.success({
                message: 'Notification',
                description: response.message,
            });          
            this.props.history.push("/signin");
        }).catch(error => {
            notification.error({
                message: 'Notification',
                description: error.message || 'Sorry! Something went wrong. Please try again!'
            });
        });
    }

    isFormInvalid() {
        return !(this.state.password.validateStatus === 'success'
        );
    }

    render() {
        return (
            <div className="card" >
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <div className="col-md-10">
                        <h2 className="h3">Reset Password</h2>
                    </div>
                </div>
                <div >
                    <div className="col-md-12">

                    <Form onSubmit={this.handleSubmit} className="signup-form">
                        <FormItem 
                            label="New Password"
                            validateStatus={this.state.password.validateStatus}
                            help={this.state.password.errorMsg}>
                            <Input 
                                size="large"
                                name="password" 
                                type="password"
                                autoComplete="off"
                                placeholder="A password between 6 to 20 characters" 
                                value={this.state.password.value} 
                                onChange={(event) => this.handleInputChange(event, this.validatePassword)} />    
                        </FormItem>
                        <FormItem>
                            <Button type="primary" 
                                htmlType="submit" 
                                size="large" 
                                className="signup-form-button"
                                disabled={this.isFormInvalid()}>Reset</Button>
                        </FormItem>
                    </Form>
                    </div>

                </div>
            </div>
        
        );
    }

    // Validation Functions
    validatePassword = (password) => {
        if(password.length < PASSWORD_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Password is too short (Minimum ${PASSWORD_MIN_LENGTH} characters needed.)`
            }
        } else if (password.length > PASSWORD_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Password is too long (Maximum ${PASSWORD_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };            
        }
    }

}

export default ChangePassword;