import React, { Component } from 'react';

import './css/Home.css';

class PrivatePolicy extends Component {

    render() {
        return (
            <div>
                <title>Private Policy</title>
                {/* <link rel="icon" type="image/x-icon" th:href="@{|${cdnResource}resources/assets/img/bilrost_y3.ico|}" />
                <link rel="alternate" href="https://bilrost.io/" hrefLang="x-default" /><link rel="alternate" href="https://bilrost.io/" hrefLang="en" />
                {/* Google Tag Manager */}
                {/* End Google Tag Manager */}
                {/* Font Awesome icons (free version)*/}

         
                <div className="container">
                    <div>
                    <br/>
                    <h3>Private Policy</h3>
                    <p>Bilröst (International) (“Bilröst” or “we”) respects your concerns about privacy. This Privacy Notice (the “Notice”) applies to personal data that we collect and it describes how we collect it, the types of personal data we obtain, how we use the personal data and with whom we share it. We also describe the rights you may have and how you can contact us about our privacy practices. Bilröst is the data controller in respect of personal data that we collect. Our contact details can be found at the ‘How To Contact Us’ section at the end of this Notice. Visitors to bilrost.io (the “Site”) are also subject to important limitations which can be found under ‘Legal’ at bilrost.io.</p>
                    <h4>1. How We Obtain Information About You</h4>
                    <p>We may collect personal data that you choose to provide to us through the Site or through meetings or various media such as Linked-In or published industry news. This personal data may include, but not be limited to, your first and last name, physical office address, company name, job title, e-mail address and/or telephone number. Examples of when we may collect this data include when you:</p>
                    <p>Fill out a form on the Site;<br />Sign-up to receive direct marketing communications on the Site;<br />Sign-up to a feature that we offer on the Site and you provide your personal data in connection with that feature;</p>
                    <p>Attend a meeting or event that we or an affiliate has organised; or
                        Communicate with us (for example, on a social media platform or by sending us an email.
                        When you visit our Sites, we also may collect certain information by automated means using technologies such as cookies, non-cookie-based tokens, web server logs, tracking pixels, and web beacons. Cookies are files that websites send to your computer or other Internet-connected device to identify your browser uniquely or to store information or settings in your browser. Your browser may tell you when you receive certain types of cookies and how to restrict or disable certain cookies. Please note, however, that without cookies, you may not be able to use all of the features of our Site. Non-cookie-based tokens are encoded URL-based identifiers that track e-mail click-thru activity or time-sensitive password reset keys and will work in scenarios where cookies are disabled or a session has not been initiated. Where permitted by law, we may acquire personal data about you from third parties. This may include personal data shared between affiliates, publicly-available profile information (such as your preferences and interests) on third party social media sites (such as Linked-In, Facebook and Twitter), and marketing lists acquired from third party marketing agencies. We may also collect personal data in other contexts that we will notify you of at the time.</p>
                    <h4>2. Information We Obtain</h4>
                    <p>Information That You Provide to Us
                        Personal data that you provide directly to us will be apparent from the context in which you provide it, for example:
                        If you fill out a form on our Site, you will generally provide your name, contact details and any other information requested by the form, such as the reason for your enquiry or your product preferences;
                    </p>
                    <p>If you sign up to receive electronic marketing communications from us, you will generally provide your name, email address and other contact information, and your product preferences;
                        If you sign up to a feature on our Site, you will generally provide your name and contact information and any other information necessary to access the feature.
                        If you attend a meeting or event you will generally provide your name and contact details.
                        Each form on our Site varies in the information required and collected. In most cases, an asterisk (*) indicates the required information on a form. You may choose to provide additional information within fields that are not required.
                    </p>
                    <p>Information That We Collect Automatically
                        Our web servers may log information such as your operating system type, browser type, domain, and other system settings, as well as the language your system uses and the country and time zone where your device is located. The web server logs may also record information such as the address of the web page that referred you to our Sites and the IP address of the device you use to connect to the Internet. They may also log information about your interaction with the Sites, such as which pages you visit. To control which web servers collect information by automated means, we may place tags called “web beacons” — small files that link web pages to particular web servers and their cookies. We may also collect information from your browser, such as your browsing history, and use it in conjunction with data gathered from forms and e-mails to help us understand and respond to your needs.
                    </p>
                    <p>“Do Not Track” Signals</p>
                    <p>Your browser settings may allow you to transmit a “Do Not Track” signal to websites and online services you visit. Like many other websites and online services, we do not currently process or respond to “Do Not Track” signals from your browser or to other mechanisms that enable choice. Both we and others (such as our service providers) may collect personal data about our visitors’ online activities over time and across third-party websites.</p>
                    <p>Social Media Widgets</p>
                    <p>The Site includes social media functions, such as the Blog, Twitter, LinkedIn, and Facebook widgets. These widgets may collect information about which pages you visit on the Site and the IP address of the device you use to connect to the Internet. The widgets may also set a cookie to ensure the features are functioning properly. Social media functions and widgets are hosted either by a third party or directly on the Site. Your interactions with the social media functions and widgets located on the Site are governed by the privacy policies of the companies that provide them. If you use any of the social media functions or widgets on our Site, we strongly suggest you review the privacy policies of the companies that provide those functions and features.
                    </p>
                    <p>LinkedIn Members
                    </p>
                    <p>If you are a LinkedIn member and you visit our Site from LinkedIn, our Site may use tracking pixels — transparent graphic images placed on web pages — which, in combination with cookies, collect information contained within your LinkedIn profile as well as your access of and interaction with the web pages that contain the pixels. Bilröst works with LinkedIn to provide Sponsored Updates and advertisements to your LinkedIn news feed. If you complete a form on our Site you will receive Sponsored InMails in your LinkedIn email account based on your visits to the Site and your perceived interests. To learn how to opt out of Sponsored InMail, navigate to Privacy &amp; Settings in your LinkedIn account, choose the "Communications" tab, and then click "Turn on/off Partner InMail" to disable Sponsored InMails</p>
                    <p>Information That We Obtain From Third Parties
                        Information that we collect from third parties will generally consist of publicly-available profile information (such as your preferences and interests), for example from public social media posts or relevant industry publications.
                    </p>
                    <h4>3. How We Use Information That We Obtain</h4>
                    <p>Information That You Provide to Us
                        We or a company with who we share information as stated in section 4 below, may use the personal data that you provide to us to
                    </p>
                    <ul>
                        <li>respond to your inquiries;</li>
                        <li>ask a question;</li>
                        <li>provide announcements about products and future events;</li>
                        <li>conduct surveys;</li>
                        <li>consider your application for employment;</li>
                        <li>operate, evaluate, and improve our business within our group of companies;</li>
                        <li>for shared company and Bilröst group systems (such as CRM and email systems, if any);</li>
                        <li>for finance and administration purposes and</li>
                        <li>for other reasons related to offering and improving our products and services.</li>
                        <li>We may store your personal data on our systems or shared Bilröst group systems to facilitate these purposes. We use the personal data for these purposes because we have a legitimate business interest in providing services to our customers and other interested individuals that is not overridden by your interests, rights and freedoms to protect personal data about you.</li>
                    </ul>
                    <p>We may also use the information to protect against and prevent fraud, claims, and other liabilities and to comply with or enforce applicable legal requirements, industry standards, and our policies and terms. We use personal data for these purposes when it is necessary to protect, exercise or defend our legal rights, or when we are required to do so by law that applies to us.</p>
                    <p>Information That We Collect Automatically
                        We use personal data that we collect automatically through cookies, non-cookie-based tokens, web beacons, and other automated means for purposes such as customizing and enhancing our visitors’ visits to the Site, facilitating use of the Sites, collecting statistics about your visits to the Site, and understanding the manner in which our visitors browse the Site. We also use the information to help diagnose technical and service problems, administer the Site, and identify visitors to the Site. We use Google Analytics data to determine how much time visitors spend on web pages of the Site, how visitors navigate through the Site, and how we may tailor the Site to meet the needs of our visitors. We use the personal data for the purposes described above because we have a legitimate interest in operating and improving our Site that is not overridden by your interests, rights and freedoms to protect personal data about you. The service providers that administer those services may use cookies and web beacons to help us analyze how users use the Site. The information collected by the cookies and web beacons (including your IP address) is available to the service providers, which use the information to evaluate your use of the Site.
                    </p>
                    <p>To learn how to opt out of Google Analytics, please visit their website
                        To learn more about the cookies that Bilröst uses on this Site, as well as to control your cookie settings on this Site, please read our Cookie Policy.
                        Other Uses of Information
                        In addition to the uses described above, we may use personal data that you provide to us or that we collect for other purposes. Where that is the case, we will provide an additional notice to you that describes the purposes for which we will use the personal data and our legal basis for doing so.
                    </p>
                    <h4>4. Information We Share</h4>
                    <p>We do not sell or otherwise disclose personal data that you provide to us or that we collect on our Site, except as described here. We may share personal data you provide to us or that we collect on the Site with:</p>
                    <p>Other companies in the Bilröst group; and<br />
                        service providers that perform services on our behalf.
                    </p>
                    <p>We may share personal data with our affiliates for a number of reasons, including because:</p>
                    <p>You have requested information about our affiliates’ products and services;<br />
                        They may be better placed to respond to your need or request;<br />
                        They perform a centralized function on our behalf.
                    </p>
                    <p>Details of the other companies in the Bilröst group, including the countries in which they are located can be found here.</p>
                    <p>We may share personal data with service providers that perform services on our behalf such as payment service providers, analytics providers, CRM providers, hosting providers and advisers. All service providers have entered into legally binding agreements requiring them to use or disclose personal data only as necessary to perform services on our behalf or to comply with applicable legal requirements.
                    </p>
                    <p>In addition, we may disclose personal data about you (a) if we are required or permitted to do so by law or legal process, for example due to a court order or a request from a law enforcement agency, (b) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss, (c) in connection with an investigation of suspected or actual fraudulent or other illegal activity, and (c) in the event we sell or transfer all or a portion of our business or assets (including in the event of a reorganization, dissolution, or liquidation).</p>
                    <h4>5. Data Transfers</h4>
                    <p>We may transfer the personal data that we collect about you to recipients in countries other than the country in which the personal data originally was collected. Those countries may not have the same data protection laws as the country in which you initially provided the personal data. When we transfer your personal data to recipients in other countries (such as the U.S.), we will protect that personal data as described in this Notice.</p>
                    <p>If you are located in the European Economic Area (“EEA”), we will comply with applicable legal requirements providing adequate protection for the transfer of personal data to recipients in countries outside of the EEA and Switzerland. In all such cases, we will only transfer your personal data if:</p>
                    <p>The country to which the personal data will be transferred has been granted a European Commission adequacy decision;
                        We have put in place appropriate safeguards in respect of the transfer, for example the EU Model Contracts.
                    </p>
                    <p>You may request a copy of the safeguards that we have put in place in respect of transfers of personal data by contacting us as described in the How To Contact Us section below.</p>
                    <h4>6. How Long We Keep Information</h4>
                    <p>The time period for which we keep personal data depends on the purpose for which we collected it. In all cases we keep it for as long as necessary to fulfil the purposes for which we collected it. We will then delete the personal data, unless we are legally required to retain it or if we need to retain it in order to comply with our legal obligations (for example, for tax and accounting purposes).</p>
                    <p>Subject to any applicable legal requirements, we typically retain personal data as follows:
                    </p>
                    <ul>
                        <li>Personal data you provide to us through our Site: we keep this personal data for as long as necessary in order to respond to your request, and for a short further period in case you send us further requests.</li>
                        <li>Personal data you provide when you sign-up to receive direct marketing communications: we keep most of this personal data for the duration of our relationship with you until you opt-out or we do not have any contact with you for a long period of time.</li>
                        <li>Personal data collected for analytics purposes: we keep this personal data for a short period of time necessary for us to carry out the analytics.</li>
                        <li>Website logs: we keep Site audit logs, which may contain your personal data, for as long as necessary to fulfil the purpose for which it was collected.</li>
                        <li>Personal data collected by us from meetings, media or similar: we keep this data for as long as we genuinely believe we may need it for our legitimate business interests.</li>
                    </ul>
                    <h4>7. Your Rights and Choices</h4>
                    <p>If you are located in the European Economic Area (“EEA”) or Switzerland, you may have the following rights in relation to personal data that we hold about you:</p>
                    <ul>
                        <li>To request confirmation of whether we process personal data relating to you, and if so, to request a copy of that personal data;</li>
                        <li>To request that we rectify or update your personal data that is inaccurate, incomplete or outdated.</li>
                        <li>To request that we erase your personal data in certain circumstances, such as where we collected personal data on the basis of your consent and you withdraw your consent;</li>
                        <li>To request that we restrict the use of your personal data in certain circumstances, such as while we consider another request that you have submitted, for example a request that we update your personal data;</li>
                        <li>Where you have given us consent to process your personal data, to withdraw your consent; and</li>
                        <li>To request that we provide a copy of your personal data to you in a structured, commonly used and machine readable format in certain circumstances.</li>
                    </ul>
                    <p>You may contact us by e-mail or as described in the “How to Contact Us” section below to exercise your rights described above.
                        You also have the right to lodge a complaint with the data protection supervisory authority in your country. You can find the contact information of the data protection supervisory authority in your country here.
                    </p>
                    <h4>8. Updates to Our Online Privacy Notice</h4>
                    <p>We may update this Notice periodically and without prior notice to you to reflect changes in our personal data practices or relevant laws. We will post the updated version and indicate at the top of the notice when it was most recently updated.</p>
                    <h4>9. How to Contact Us</h4>
                    <p>Our EU Representative</p>
                    <p>If you are located in the European Union (“EU”) and have any questions or comments about this Notice, any issue relating to your personal data, or if you would like us to update your preferences, you may contact our EU representative.
                        Please include your name and the name of the business (Bilröst) to which your request refers.
                    </p>
                    <p>By e-mail at: hello@bilrost.io</p>
                    <p>Our UK Representative</p>
                    <p>If you are located in the United Kingdom (“UK”) and have any questions or comments about this Notice, any issue relating to your personal data, or if you would like us to update your preferences, you may contact our UK representative.</p>
                    <p>Please include your name and the name of the business (Bilröst) to which your request refers.</p>
                    <p>By e-mail at: hello@bilrost.io</p>
                    </div></div>
                </div>
        );
    }
}



export default PrivatePolicy;