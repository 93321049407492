import React, { useRef, useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import { getProfile, getSubscription } from '../util/APIUtils';
import { notification, Switch } from 'antd';


export function Upgrade() {
  const [subscriptionStartedDate, setSubscriptionStartedDate] = useState();

  const handleClick = () => {
    window.open("/checkout");
    window.location.href = "/control_panel/subscription";
  };

  useEffect(()=>{
    {/*getProfile()
      .then(response => {
        console.log(response);
        if (response.success){
            setBusinessName(response.businessName);
            setBusinessWebsite(response.businessWebsite);
            setContactNumber(response.contactNumber);
            setContactPerson(response.contactPerson);
            setEmail(response.email);
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });*/}

      getSubscription()
      .then(response => {
        console.log(response);
        if (response.success){
          if (response.subscriptionStartedDate){
            setSubscriptionStartedDate(response.subscriptionStartedDate);
          }
        } else {
          notification.error({
            message: 'Campaign',
            description: response.message || 'Sorry! Something went wrong. Please try again!'
          });
        }
      }).catch(error => {
          notification.error({
              message: 'Campaign',
              description: error.message || 'Sorry! Something went wrong. Please try again!'
          });
      });
  }, [])

  return (
    <div className="card" >
    
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="col-md-10">
        <h2 className="h3">Upgrade</h2>
      </div>

    </div>
    <div className="form-group row">
      <span className="col-sm-5"></span>
      <span className="col-sm-2">
        <Switch checkedChildren="Monthly" unCheckedChildren="Yearly" defaultChecked disabled={true}/>
      </span>
      <span className="col-sm-5"></span>
    </div>

  <div className="row justify-content-center" id="monthly_plan">
    <div className="row text-center">
      
    <div className="col-md-12">
      <div className="plan basic">
      <div className="plan-inner">
          <div className="hot">RECOMMEND</div>
          <div className="entry-title">
          <h3 style={{"color": "#FFF"}} >Standard</h3>
          <h6 style={{"color": "#FFF"}} >Starting from</h6>
          <div className="price">£189 <span>/month</span>
          </div>
          </div>
          <div className="entry-content">
          <ul>
              <li>
              {/*<s>£2268</s>*/}
              Starting from <strong>£189</strong> / month
              </li>
              <li>
              <strong>1x</strong> User
              </li>
              <li>
              <strong>£0.0095</strong> / each
              </li>
              <li>
              <strong>20000 Leads</strong> / month
              </li>
              <li>Email channel</li>
              <li>Unlimit daily email</li>
              <li>Site and event tracking</li>
              <li>Drag and drop email builder</li>
              <li>Ticket and email support</li>
          </ul>
          </div>
          <input
              className="btn btn-primary btn-xl text-uppercase "
              name="save"
              type="button"
              value="Subscribe"
              style={{ width: "50%" }}
              disabled={subscriptionStartedDate}
              onClick={handleClick}
          />
          <div className="entry-content">
          <br />
          </div>
      </div>
      </div>
  </div>

    </div>
  </div>

  {/*
  <div className="row justify-content-center" id="yearly_plan">
    <div className="row text-center">
      <div className="col-md-12">
        <div className="plan standard">
          <div className="plan-inner">
            <div className="hot">RECOMMEND</div>
            <div className="entry-title">
              <h3>Standard</h3>
              <h6>
                <s text="standard_y_1}" />
              </h6>
              <div className="price">
                [[${"{"}standard_y_2{"}"}]]{" "}
                <span id="peroid" text="standard_y_3}" />
              </div>
            </div>
            <div className="entry-content">
              <ul>
                <li>
                  <strong text="standard_y_4}" /> [[${"{"}standard_y_5{"}"}
                  ]]
                </li>
                <li>
                  <s text="standard_y_6a}" />
                  <strong text="standard_y_6b}" /> [[${"{"}standard_y_7
                  {"}"}]]
                </li>
                <li>
                  <strong text="standard_y_8}" /> [[${"{"}standard_y_9{"}"}
                  ]]
                </li>
                <li text="standard_y_10}" />
                <li text="standard_y_11}" />
                <li text="standard_y_12}" />
                <li text="standard_y_13}" />
                <li text="standard_y_14}" />
              </ul>
            </div>
            <div className="btn">
              <a
                href="standard_y_url}"
                target="_blank"
                style={{ pointerEvents: "none" }}
              >
                Upgrade
              </a>
            </div>
            <div className="entry-content">
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}

    </div>
  );
}