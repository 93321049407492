import React, { Component } from 'react';
import './App.css';


import {
  Route,
  withRouter,
  Switch
} from 'react-router-dom';

import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';

import Home from '../home/Home';
import PrivatePolicy from '../home/PrivatePolicy';
import CookiesPolicy from '../home/CookiesPolicy';
import Developer from '../home/Developer';
import Unsubscript from '../subscription/Unsubscript';
import Checkout from '../upgrade/Checkout';
import SubscribeSuccess from '../upgrade/SubscribeSuccess';
import SubscribeFail from '../upgrade/SubscribeFail';



import PollList from '../poll/PollList';
//import { EmailEditor } from '../campaign/EmailEditor';
import MasterForm from "../campaign/MasterForm";
import NewPoll from '../poll/NewPoll';
import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import ChangePassword from '../user/change_password/ChangePassword';
import ForgetPassword from '../user/forget_password/ForgetPassword';
import Profile from '../user/profile/Profile';
import ControlPanel from './ControlPanel';
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';

import bilrost_digital_y from '../home/img/bilrost_digital_y.png';

import { Layout, notification } from 'antd';
const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: true
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });    
  }

  loadCurrentUser() {
    getCurrentUser()
    .then(response => {
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false
      });
    }).catch(error => {
      this.setState({
        isLoading: false
      });  
    });
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  handleLogout(redirectTo="/", notificationType="success", description="You're successfully logged out.") {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false
    });

    this.props.history.push(redirectTo);
    
    notification[notificationType]({
      message: 'Notification',
      description: description,
    });
  }

  handleLogin() {
    notification.success({
      message: 'Notification',
      description: "You're successfully logged in.",
    });
    this.loadCurrentUser();
    this.props.history.push("/control_panel/profile");
  }

  render() {
    if(this.state.isLoading) {
      return <LoadingIndicator />
    }
    
    return (
        <Layout className="app-container">
          <Content >
          <Switch>      
                {this.state.currentUser &&
                  <Route path="/control_panel" component={ControlPanel}></Route>
                }
                {this.state.currentUser &&
                  <Route path="/checkout" component={Checkout}></Route>
                }
                {this.state.currentUser &&
                  <Route path="/subscribe/success" component={SubscribeSuccess}></Route>
                }
                {this.state.currentUser &&
                  <Route path="/subscribe/fail" component={SubscribeFail}></Route>
                }
                {this.state.currentUser &&
                  <Route path="/developer" component={Developer}></Route>
                }
                <Route path="/signin" render={(props) => <Login onLogin={this.handleLogin} {...props} />}></Route>
                <Route path="/signup" component={Signup}></Route>
                <Route path="/change_password" component={ChangePassword}></Route>
                <Route path="/forget_password" component={ForgetPassword}></Route>
                <Route exact path="/" component={Home}></Route>
                <Route path="/private_policy" component={PrivatePolicy}></Route>
                <Route path="/cookies_policy" component={CookiesPolicy}></Route>
                {/*<Route component={NotFound}></Route>*/}
                <Route component={Login}></Route>
              </Switch> 
          </Content>
        </Layout>
    );
  }
}

export default withRouter(App);
